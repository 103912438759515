import axios from "src/utils/axios";
import { URLS } from "src/utils/url";
import authService from "./authService";
import i18n from 'i18next';

const STORAGE_KEY = "beConfig";

class ConfigService {

	setConfig(c){
		localStorage.setItem(STORAGE_KEY, JSON.stringify(c));
	}

	getConfigByKey(key){
		let config = JSON.parse(localStorage.getItem(STORAGE_KEY));

		if(key) return config?.[key];
		else return config;
	}

	getConfig() {
		let isAuth = authService.isAuthenticated();

		axios({
			type: "GET",
			url: isAuth ? URLS.CONFIG.PRIVATE : URLS.CONFIG.PUBLIC, 
			headers: {
				Authorization: isAuth && `Bearer ${authService.getAccessToken()}`
			}
		})
		.then(res => {
			this.setConfig(res.data);
			if(res.data?.customerTranslation){
				i18n.changeLanguage(res.data?.customerTranslation)
			}
		})
	}

	getLogoLogin() {
		return axios
			.get(URLS.LOGO_LOGIN, {	responseType: 'blob' })
			.then(res => {
				const file = new Blob([res.data], { type: res.headers['content-type'] });
				return URL.createObjectURL(file);
			})
			.catch(err => console.error("getLogoLogin err", err))
	}
}

const configService = new ConfigService();

export default configService;
