export const STORAGE_KEY = 'searchbox';

export const OPERATORS_LIST = [
	{
		label: 'Contiene',
		value: 'contiene',
		name: 'like',
		propertyTypes: ['text']
	},
	{
		label: 'Maggiore Uguale',
		value: '>=',
		name: '>=',
		propertyTypes: ['date', 'number']
	},
	{
		label: 'Minore Uguale',
		value: '<=',
		name: '<=',
		propertyTypes: ['date', 'number']
	},
	{
		label: 'Uguale',
		value: '=',
		name: '=',
		propertyTypes: ['date', 'enum','pipeline']
	},
	{
		label: 'Inizia per',
		value: 'inizia per',
		name: 'startWith',
		propertyTypes: []
	}
]