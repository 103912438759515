import axios from 'src/utils/axios';
import { URLS } from 'src/utils/url';

class CatalogService {
	
	getReportDetailTypes() {
		return axios.get(URLS.CATALOG.REPORT_DETAIL_TYPE)
	}

	getDeliveryMethodOptions() {
		return axios.get(URLS.CATALOG.INVOICE_DELIVERY)
	}

	getCadastralMissinData() {
		return axios.get(URLS.CATALOG.CADASTRAL.MISSING_DATA)
	}

	getCadastralUnitTypes() {
		return axios.get(URLS.CATALOG.CADASTRAL.UNIT_TYPE)
	}

	getCadastralQualificationHolders() {
		return axios.get(URLS.CATALOG.CADASTRAL.QUALIFICATION_HOLDER)
	}

	getCadastralParticleTypes() {
		return axios.get(URLS.CATALOG.CADASTRAL.PARTICLE_TYPE)
	}
}

export const catalogService = new CatalogService();
