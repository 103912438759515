/* eslint-disable no-param-reassign */
import produce from 'immer';
import _ from 'lodash';
import {
  SET_BREADCRUMBS,
  SET_PAGE_TITLE,
  
} from 'src/actions/layoutActions';

const initialState = {
  breadcrumbs: []
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BREADCRUMBS: {
      const { breadcrumbs } = action.payload;

      return produce(state, (draft) => {
        draft.breadcrumbs = breadcrumbs
      });
    }

    case SET_PAGE_TITLE: {
      const { title } = action.payload;

      return produce(state, (draft) => {
        draft.pageTitle = title
      });
    }

    default: {
      return state;
    }
  }
};

export default layoutReducer;
