import axios from 'src/utils/axios';
import { URLS } from 'src/utils/url';

class ContractLineService {
	constructor() {}

	load(id) {
		return axios
			.get(`${URLS.CONTRACT_LINES}/${id}`);
	}
}

export const contractLineService = new ContractLineService();