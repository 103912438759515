import React from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router';

function BackButton() {
	const firstPage = '/mobile';
	const history = useHistory()

	function handleClick() {
		history.goBack();
	}

	if(history.location.pathname !== firstPage) {
		return (
			<IconButton color="inherit" onClick={handleClick}>
				<ArrowLeft />
			</IconButton>
		)		
	}

	return null;
}

export default BackButton;
