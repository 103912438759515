import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Portal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { alertService } from 'src/services/alert.service';

export function AlertComponent() {
	const [open, setOpen] = useState(false);
	const [alertValues, setAlertValues] = useState(alertService.alert$);

	alertService.alert$.subscribe(v => {
		if ((v?.title || v?.message) && !open) handleOpen(v);
	});

	function handleOpen(v) {
		setOpen(true);
		setAlertValues(v)
	}

	function handleClose() {
		setOpen(false);
		alertService.dismiss();
		if (alertValues?.message=="Contenuto non autorizzato")
			window.location.href = "/app";
	}

	return (
		<Portal>
			<Dialog open={open} disableBackdropClick disableEscapeKeyDown>
				<DialogTitle>{alertValues?.title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{alertValues?.message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{alertValues?.buttonText || 'Ok'}
					</Button>
				</DialogActions>
			</Dialog>
		</Portal>
	);
}