import {
	Avatar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Hidden,
	IconButton,
	makeStyles,
	MenuItem,
	Popover,
	SvgIcon,
	TextField,
	Typography
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { logout } from 'src/actions/accountActions';
import bpartnerService from 'src/services/bpartnerService';
import layoutService from 'src/services/layoutService';
import { PROFILING } from 'src/utils/profilingKeys';
import userInfo from 'src/services/userInfo';
import { anagraphicService } from 'src/services';
import { URLS } from 'src/utils/url';


const useStyles = makeStyles(theme => ({
	avatar: {
		height: 32,
		width: 32,
		marginRight: theme.spacing(1)
	},
	popover: {
		width: 'auto'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	},
	noCursor: {
		cursor: 'default'
	},
	cursor: {
		cursor: 'pointer',
		textAlign: 'right'
	}
}));

function Account() {
	const classes = useStyles();
	const history = useHistory();
	const ref = useRef(null);
	const dispatch = useDispatch();
	const account = useSelector(state => state.account);
	const { enqueueSnackbar } = useSnackbar();
	const [isOpen, setOpen] = useState(false);
	const [isOpenDialog, setOpenDialog] = useState(false);
	const bpartner = bpartnerService.getBpartner()
		? bpartnerService.getBpartner()
		: account.user?.extensions?.profiles
		? account.user?.extensions?.profiles[0]?.bpartnerId
		: null;
	const [isLoading, setIsLoading] = useState(false);

	const [values, setValues] = useState({
		bpartner: bpartner
	});
	const [expanded, setExpanded] = useState(false);
	const handleOpen = () => {
		setExpanded(!expanded);
		setOpen(true);
	};

	const handleClose = () => {
		setExpanded(!expanded);
		setOpen(false);
	};
	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleChange = (field, value) => {
		setValues({
			...values,
			[field]: value
		});
		handleSave(value);
	};

	const handleSave = value => {
		bpartnerService.setBpartner(value);
		//window.location.href = `/app`;
		window.location.href = '/app/anagraphic/'+value;
	};

	const isLegal = userInfo.can(PROFILING.ROLE.LEGAL);
	const isCustomer = userInfo.can(PROFILING.ROLE.CUSTOMER) && !userInfo.is(PROFILING.ADMIN) && !userInfo.can(PROFILING.ROLE.LEGAL);
	const isAreaManager = userInfo.can(PROFILING.ROLE.MANAGER);

	function getName(id) {
		if (account.user?.extensions?.profiles) {
			let bp = account.user?.extensions?.profiles.find(
				x => x.bpartnerId === parseInt(id)
			);
			return bp?.bpartnerName;
		}
	}

	const handleLogout = async () => {
		try {
			handleClose();
			await dispatch(logout());
			history.push('/');
		} catch (error) {
			enqueueSnackbar('Impossibile effettuare il logout', {
				variant: 'error'
			});
		}
	};

	const isEquals = (value1, value2) => {
		return parseInt(value1) === parseInt(value2);
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={isLoading}>
				<CircularProgress />
			</Backdrop>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				ref={ref}
				onClick={handleOpen}
				className={classes.cursor}
				ml={2}
			>
				<Hidden smDown>
					<Box>
						<Typography variant="h6" color="inherit">
							{account.user.name}
						</Typography>
						{(!isAreaManager && !isLegal &&
						<Typography variant="h6" color="inherit">
							{getName(bpartner)}
						</Typography>
						)}
					</Box>
					<Box flexGrow="1" />
					<IconButton
						className={clsx(classes.expand, {
							[classes.expandOpen]: expanded
						})}
						color="inherit"
					>
						<ExpandMoreIcon />
					</IconButton>
				</Hidden>
				{(layoutService.isMobile() ||
					layoutService.isTablet()) && (
						<Avatar
							alt="User"
							className={classes.avatar}
							src={account.user.avatar}
						/>
					)}
			</Box>
			<Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				classes={{ paper: classes.popover }}
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
			>
				<MenuItem className={classes.noCursor}>
					<Box ml={3} mb={1} mt={1}>
						<Box display="flex">
							<Avatar
								alt="User"
								className={classes.avatar}
								src={account.user.avatar}
							/>
							<Box>
								<Typography variant="h5" color="textSecondary">
									{account.user.email}
								</Typography>
								<Typography
									variant="h6"
									color="textSecondary"
									component={RouterLink}
									to="/app/account"
								>
									Modifica Profilo
								</Typography>
							</Box>
						</Box>
					</Box>
				</MenuItem>
				<Divider />
				{isCustomer && bpartner && account.user?.extensions?.profiles && (
					<>
					<Box display="flex" ml={3} mb={1} mt={1}>
							<Typography variant="h6" color="textSecondary">
								Anagrafica
							</Typography>
						</Box>
						{(account.user?.extensions?.profiles || []).map(profile => (						
							<MenuItem
								onClick={() => handleChange('bpartner', profile?.bpartnerId)
									/*!isEquals(bpartner, profile?.bpartnerId)
										? handleChange('bpartner', profile?.bpartnerId)
										: null*/
								}
							>
								{isEquals(bpartner, profile?.bpartnerId) && (
									<SvgIcon fontSize="small">
										<CheckIcon />
									</SvgIcon>
								)}
								<Box
									display="flex"
									ml={isEquals(bpartner, profile?.bpartnerId) ? 1 : 3}
									mb={1}
									mt={1}
								>
									<Typography variant="h6" color="textSecondary">
										{profile.bpartnerName}
									</Typography>
								</Box>
							</MenuItem>
						))}
						<Divider />
					</>
				)}				
				{1==0 && isCustomer && (
					<>
						<MenuItem component={RouterLink} //to="/app/anagraphic/">
						//to={'/app/anagraphic/'+ bpartner}
						//to={'/mobile/anagraphics'}
						to={`/app/crm/customers/'+ bpartner+'/view`}
						>
							<Box display="flex" ml={3} mb={1} mt={1}>
								<Typography variant="h6" color="textSecondary">
									Anagrafica
								</Typography>
							</Box>
						</MenuItem>
						<Divider />
					</>
				)}
				<MenuItem onClick={handleLogout}>
					<Box display="flex" ml={3} mb={1} mt={1}>
						<Typography variant="h6" color="textPrimary">
							Esci
						</Typography>
					</Box>
				</MenuItem>
			</Popover>
			<Dialog
				open={isOpenDialog}
				onClose={handleCloseDialog}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>{'Cambia Business Partners'}</DialogTitle>
				<Divider />
				<DialogContent>
					<Box mt={2}>
						<TextField
							fullWidth
							label="Business Partner"
							name="bpartner"
							onChange={event => handleChange('bpartner', event.target.value)}
							select
							SelectProps={{ native: true }}
							value={values.bpartner}
							variant="outlined"
						>
							{(account.user?.extensions?.profiles || []).map(bp => (
								<option key={bp?.bpartnerId} value={bp?.bpartnerId}>
									{bp?.bpartnerName}
								</option>
							))}
						</TextField>
					</Box>
				</DialogContent>
				<Box m={2}>
					<Button
						variant="contained"
						color="secondary"
						fullWidth
						onClick={handleSave}
					>
						Cambia
					</Button>
				</Box>
			</Dialog>
		</>
	);
}

export default Account;
