import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, SvgIcon, makeStyles, Badge } from '@material-ui/core';
import {
	Bell as BellIcon,
	Package as PackageIcon,
	MessageCircle as MessageIcon,
	Truck as TruckIcon
} from 'react-feather';
import { getNotifications } from 'src/actions/notificationsActions';

const iconsMap = {
	order_placed: PackageIcon,
	new_message: MessageIcon,
	item_shipped: TruckIcon
};

const useStyles = makeStyles(theme => ({
	popover: {
		width: 320
	},
	icon: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText
	}
}));

function Communications() {
	const classes = useStyles();
	const nCommunications = 0;

	return (
		<IconButton
			color="inherit"
			component={RouterLink}
			to={'/mobile/communications'}
		>
			<Badge badgeContent={nCommunications} color="error">
				<SvgIcon>
					<BellIcon />
				</SvgIcon>
			</Badge>
		</IconButton>
	);
}

export default Communications;
