/* eslint-disable import/prefer-default-export */
export const THEMES = {
  // DEFAULT
  LIGHT_DEFAULT: {
    name: 'LIGHT_DEFAULT',
    label: 'CHIARO DEFAULT',
  },
  DARK: {
    name: 'DARK',
    label: 'SCURO',
  },

  // CUSTOM
  LIGHT_FLG: {
    name: 'LIGHT_FLG',
    label: 'CHIARO',
    parent: 'LIGHT_DEFAULT'
  },
  LIGHT_RRG: {
    name: 'LIGHT_RRG',
    label: 'CHIARO',
    parent: 'LIGHT_DEFAULT'
  },
}
