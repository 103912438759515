import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import configService from './services/configService';
import { CONSTANT_KEYS } from './utils/constant';

const TRANSLATION = configService.getConfigByKey(
	CONSTANT_KEYS.CUSTOMER_TRANSLATION
);


i18n
	.use(XHR)
	.use(LanguageDetector)
	.init({
		debug: true,
		initImmediate: false,
		lng: TRANSLATION,
		backend: {
			/* translation file path */
			loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
		},
		fallbackLng: TRANSLATION,
		// have a common namespace used around the full app
		ns: ['translations'],
		defaultNS: 'translations',

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false, // not needed for react!!
			formatSeparator: ','
		},

		react: {
			wait: true
		}
	});

export default i18n;
