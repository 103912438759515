import {
	Box,
	colors,
	makeStyles,
	Tooltip,
	Typography
} from '@material-ui/core';
import React from 'react';
import Label from './Label';
import configService from 'src/services/configService';
import { CONSTANT_KEYS } from 'src/utils/constant';

const useStyles = makeStyles(() => ({
	root: {},
	close: {
		backgroundColor: colors.green[500],
		color: colors.common.white
	},
	open: {
		backgroundColor: colors.orange[500],
		color: colors.common.white
	},
	progress: {
		backgroundColor: colors.blue[500],
		color: colors.common.white
	},
	draft: {
		backgroundColor: colors.lime[500],
		color: colors.common.white
	}
}));

function AvatarCommons({
	status,
	inProgress,
	statusOk,
	statusKo,
	labels: { labelOk, labelKo, labelDraft },
	tooltipTxt
}) {
	const classes = useStyles();
	const agencyStyle = configService.getConfigByKey(CONSTANT_KEYS.BE_AGENCY_STYLE);
	return (
		<Tooltip title={tooltipTxt ? tooltipTxt : ''}>
			<Box display="flex" alignItems="center">
				<Label
					className={
						(inProgress && !agencyStyle)//label in lavorazione non visibile a tutti i client
						? classes.progress
						: status === statusOk
						? classes.open
						: status === statusKo
						? classes.close
						: status !== null
						? classes.draft
						: null
					}
				>
					<Typography>
						{(inProgress && !agencyStyle) 
							? 'In Lavorazione'
							: status === statusOk
							? labelOk
							: status === statusKo
							? labelKo
							: status !== null
							? labelDraft
							: null}
					</Typography>
				</Label>
			</Box>
		</Tooltip>
	);
}

export default AvatarCommons;
