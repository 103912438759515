import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	AppBar,
	fade,
	Toolbar,
	makeStyles,
	Box,
} from '@material-ui/core';
import Title from './Title';
import MobileMenu from './MobileMenu';
import BackButton from './BackButton';
import Communications from './Communications';
import { CONSTANT_KEYS } from 'src/utils/constant';
import configService from 'src/services/configService';
import { THEMES } from 'src/constants';


var topBarColor = null;

function setTopBarColor(color) {
	topBarColor = color;
}

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: theme.zIndex.drawer + 100,
		...(theme.name === THEMES.LIGHT_DEFAULT.name && topBarColor != null && topBarColor != 'undefined'
			? {
					boxShadow: 'none',
					backgroundColor: fade(topBarColor, 1)
			  }
			: {
				boxShadow: 'none',
				backgroundColor: theme.palette.primary.main
			}),
		...(theme.name === THEMES.DARK.name
			? {
					backgroundColor: theme.palette.background.default
			  }
			: {})
	},
	toolbar: {
		minHeight: 64
	},
	logo: {
		width: "100%",
		height: "100%",
		maxWidth: 200
	}
}));

function TopBar({
	className,
	onMobileNavOpen,
	...rest
}) {
	const topBarColor = configService.getConfigByKey(CONSTANT_KEYS.TOP_BAR_COLOR);
	setTopBarColor(topBarColor);
	const classes = useStyles();

	return (
		<AppBar
			className={clsx(classes.root, className)}
			{...rest}
		>
			<Toolbar className={classes.toolbar}>
				<BackButton />
				<Box
					ml={2}
					flexGrow={1}
				/>

				<Communications />
				
				<Box ml={2}>
					<MobileMenu onMobileNavOpen={onMobileNavOpen} />
				</Box>
				</Toolbar>
			<Title/>
		</AppBar>
	);
}

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func
};

export default TopBar;
