/* eslint-disable react/no-array-index-key */
import React, { lazy } from 'react';

const webmailRoutes = [
	{
		exact: true,
		path: '/app/webmail/templates/',
		component: lazy(() => import('./TemplateMessages'))
	},
	{
		exact: true,
		path: '/app/webmail/templates/edit',
		component: lazy(() => import('./TemplateMessages/Edit'))
	},
	{
		exact: true,
		path: '/app/webmail/templates/:entityId/edit',
		component: lazy(() => import('./TemplateMessages/Edit'))
	},
	{
		exact: true,
		path: '/app/webmail/templates/create',
		component: lazy(() => import('./TemplateMessages/Edit'))
	}
];

export default webmailRoutes;
