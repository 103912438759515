export const URLS = {
	AGENCIES: {
		AGENCIES: '/api/v1/sales/agencies',
		DETAILS: 'details',
		CONTRACTS: 'contracts',
		AGENTS: 'agents',
		CONFIGURATIONS: 'configuration'
	},
	AGENTS: '/api/v1/sales/agents',
	AGENCIES_AGENTS: '/api/v1/sales/agenciesAgents',
	ANAGRAPHIC: '/api/v1/anagraphic',
	ANAGRAPHIC_CUSTOMER: '/api/v1/crm/anagraphic',
	ATECO_CODE: '/api/v1/crm/atecoCode',
	BPARTNERS: '/api/v1/crm/bpartners',
	BPARTNERS_SEARCH_BY_FISCAL_CODE: '/api/v1/crm/bpartners/findByFiscalCode',
	BPARTNERS_SEARCH_BY_VAT_CODE: '/api/v1/crm/bpartners/findByVatCode',
	BPARTNERS_SEARCH_BY_FISCAL_CODE_AND_VAT: '/api/v1/crm/bpartners/findByFiscalCodeAndVat',
	BPARTNERS_SEARCH_BY_DISTRIBUTOR_CODE: '/api/v1/crm/bpartners/findDistributorByCode',
	CONFIG: {
		PRIVATE: '/api/v1/config',
		PUBLIC: '/public/config'
	},
	CONSOLE_PYTHON: '/api/v1/console/execute',
	CONTACTS: '/api/v1/crm/bpartners/contacts',
	CONTRACT_LINES: '/api/v1/crm/contractLines',
	CONTRACT_LINES_GROUPED_PODPDR:'/api/v1/crm/contractLinesGroupedByPodPrd',
	CONTRACTS: '/api/v1/crm/contracts',
	CONTRACTS_LINES_PROVV: '/api/v1/crm/contractsLineProvv',
	CUSTOMERS: '/api/v1/crm/customers',
	CUSTOMER_UPDATE: '/api/v1/crm/updateCustomer',
	CUSTOMERTYPE: '/api/v1/crm/customerType',
	DASHBOARD: '/api/v1/reports/dashboard',
	DASHBOARD_CUSTOMER: '/api/v1/reports/dashboard/customer',
	DISTRIBUTOR_POWER: '/api/v1/crm/distributor/power',
	DISTRIBUTOR_GAS: '/api/v1/crm/distributor/gas',
	FILE: '/api/v1/files',
	FUNCTION_DEFINITION: '/api/v1/functionDefinition',
	FOLDER: '/api/v1/folders',
	INVOICE: '/api/v1/crm/invoice',
	INVOICES: '/api/v1/crm/invoices',
	ALL_CONSUMPTION_INVOICE: '/api/v1/crm/allConsumption/invoice',
	ALL_HOURS_CONSUMPTION_INVOICE: '/api/v1/crm/allHoursConsumption/invoice',
	ALIGN_INVOICES: '/api/v1/crm/invoices/alignInvoices',
	SMS_INVOICES: '/public/invoices',
	LOGO_LOGIN: '/public/logoLogin',
	LOGS: 'api/v1/logs/logs',
	MAIL: {
		EPORTAL:{
			MAILBOX: 'api/v1/eportal/findmailboxes',
			MAILS: 'api/v1/eportal/findmails',
			TAGMAILS: 'api/v1/eportal/tags/findmails',
			RESTORE: 'api/v1/eportal/restoremail',
			EMPTYTRASH: 'api/v1/eportal/emptytrash',
			ARCHIVE_SELECTED: 'api/v1/eportal/archiveselected',
			SEND: 'api/v1/eportal/mails/sendEportal',
			DETATCH_TICKET: 'api/v1/eportal/mails/detatchTicket'
		},
		FOLDER: 'api/v1/mails/folders',
		MAILS: 'api/v1/mails',
		SEND: 'api/v1/mails/send',
		MAILBOX: 'api/v1/mailboxes',
		TAGS: 'api/v1/mailtags'
	},
	POD_PDR: '/api/v1/crm/contracts/podpdr',
	PRACTICE: '/api/v1/crm/practice',
	PRECONTRACTS_LINE: '/api/v1/crm/preContractsLine',
	PRECONTRACT: {
		PIPELINES: '/pipeline',
		PODS: '/pods',
		PDRS: '/pdrs',
		CONTACTS: '/contacts',
		DOCS: '/docs',
		OFFER: '/offer'
	},
	PRECONTRACT_LINE: {
		ENERGY: '/E',
		GAS: '/G',
		SEARCH_POD: 'api/v1/crm/preContractsLine/searchPOD/E',
		IMPORT_POD: 'api/v1/crm/preContractsLine/importPOD/E',
		SEARCH_PDR: 'api/v1/crm/preContractsLine/searchPDR/G',
		IMPORT_PDR: 'api/v1/crm/preContractsLine/importPDR/G'
	},
	PRECONTRACTS: '/api/v1/crm/preContracts',
	INVOICE_SIMULATOR: '/api/v1/crm/contracts/simulate',
	PROFILES: {
		PROFILES: '/api/v1/profiles',
		ALL_NOTIFICATIONS: '/api/v1/profiles/allnotifications',
		NOTIFICATIONS: '/api/v1/profiles/notifications',
		ME: '/api/v1/profiles/me',
		LOGIN: '/api/v1/profile/login',
		RESET: '/api/v1/profile/reset',
		REGISTRATION: '/api/v1/eportal/profiles/registration',
		MY_WORKSPACE: '/api/v1/eportal/profiles/myWorkspace',
		MY_WORKSPACE_ID: '/api/v1/eportal/profiles/myWorkspaceId'
	},
	SECURITY: {
		DESCRIBE: '/api/v1/security/describe',
	},
	SELF_READINGS: '/api/v1/crm/selfReadings',
	SELLERS: '/api/v1/crm/sellers',
	TENANTS: '/api/v1/tenants',
	DOCUMENTS: '/api/v1/documents',
	WORKSPACES: '/api/v1/workspaces',
	WORKSPACES_TYPOLOGY: '/api/v1/workspacesType',
	STORYBOARDS: '/api/v1/storyboards',
	VALIDATE_TOKEN: '/public/validatetoken',
	DICTIONARY: {
		CONTRACT_TYPE: '/api/v1/dictionary/type',
		PLACE_NAME: '/api/v1/dictionary/placeNames',
		PREV_SUPPLY: '/api/v1/dictionary/prevSupply',
		MARKET_SOURCE: '/api/v1/dictionary/marketSource',
		PLUGIN_VOLTAGE: '/api/v1/dictionary/plugInVoltageType',
		USE_TYPE: '/api/v1/dictionary/useType',
		SUB_USE_TYPE: '/api/v1/dictionary/subUseType',
		RESIDENT: '/api/v1/dictionary/resident',
		VAT_CATEGORY: '/api/v1/dictionary/vatCategory',
		HAS_CADASTRE: '/api/v1/dictionary/hasCadastreData',
		CODE_DATA_EMPTY: '/api/v1/dictionary/codeDataEmpty',
		TYPE_UNIT_IMMO: '/api/v1/dictionary/typeUnitImmo',
		TITLE_OWNER: '/api/v1/dictionary/titleOwner',
		TYPE_PART: '/api/v1/dictionary/typePart',
		EXCISE: '/api/v1/dictionary/excise',
		USE_CATEGORY: '/api/v1/dictionary/useCategory',
		WITHDRAW_CLASS: '/api/v1/dictionary/withdraw_class',
		DOCS: '/api/v1/dictionary/docs',
		ACCESS_WEB: '/api/v1/dictionary/accessWeb',
		INVOICE_FREQ: '/api/v1/dictionary/invoiceFreq',
		PAYMENT_TYPE: '/api/v1/dictionary/paymentType',
		PAYMENT_TERMS: '/api/v1/dictionary/paymentTerms',
		LOCALITY: '/api/v1/dictionary/locality',
		POSTAL_CODE: '/api/v1/dictionary/postalCode',
		PROVINCE: '/api/v1/dictionary/province',
		OFFERS: '/api/v1/dictionary/offers',
		PRICE_LIST_TYPE_ALL: '/api/v1/dictionary/priceListTypes',
		PRICE_LIST_TYPE: '/api/v1/dictionary/priceListType',
		PRICE_LISTLINE_TYPE: '/api/v1/dictionary/priceListLineType',
		PRICE_LIST: '/api/v1/dictionary/priceList',
		PRICE_LIST_LINE: '/api/v1/dictionary/priceListLine',
		PRICE_LIST_SYNC: '/api/v1/dictionary/priceLists/sync',
		TAGS: '/api/v1/dictionary/tags',
		PRACTICE: '/api/v1/dictionary/practice'
	},
	CATALOG: {
		BPSTATUS: '/api/v1/catalog/bpstatus',
		BPCLASSIFICATION: '/api/v1/catalog/bpclassification',
		ENERGYTYPE: '/api/v1/catalog/energyType',
		CUSTOMERTYPE: '/api/v1/catalog/customerTypes',
		PLACE_NAME: '/api/v1/catalog/placeNames',
		CONCL_START_TYPE: '/api/v1/catalog/conclStartType',
		CONCL_END_TYPE: '/api/v1/catalog/conclEndType',
		LOCALITY: '/api/v1/catalog/locality',
		INVOICE_DELIVERY: '/api/v1/catalog/invoiceDeliveryMethod',
		INVOICE_CADENCE: '/api/v1/catalog/invoiceCadence',
		PAYMENT_METHOD: '/api/v1/catalog/paymentMethodType',
		PAYMENT_METHOD_NOCOMP: '/api/v1/catalog/paymentMethodTypeNoComp',
		PAYMENT_TERMS: '/api/v1/catalog/paymentType',
		REPORT_DETAIL_TYPE: '/api/v1/catalog/reportDetailType',
		CODE_ATECO: '/api/v1/catalog/codAteco',
		MARKET_ORIGIN: '/api/v1/catalog/marketOrigin',
		TYPOLOGY_USES: '/api/v1/catalog/typologyUses',
		TYPOLOGY_SUBUSES: '/api/v1/catalog/typologySubUses',
		VAT_CATEGORY: '/api/v1/catalog/vatCategory',
		WITHDRAW_CLASS: '/api/v1/catalog/classWithdrawal',
		USE_CATEGORY: '/api/v1/catalog/useCategory',
		POWER_LEVEL: '/api/v1/catalog/powerLevel',
		TARIFF_OPTION: '/api/v1/catalog/tariffOptions',
		WORKING_STATES: '/api/v1/catalog/workingStates',
		COUNTER_TYPE: '/api/v1/catalog/counterType',
		CADASTRAL: {
			MISSING_DATA: '/api/v1/catalog/cadastral/missingData',
			UNIT_TYPE: '/api/v1/catalog/cadastral/unitType',
			QUALIFICATION_HOLDER: '/api/v1/catalog/cadastral/qualificationHolder',
			PARTICLE_TYPE: '/api/v1/catalog/cadastral/particleType'
		}
	},
	PARTNERS: '/api/v1/crm/partners',
	INSPECTIONS: '/api/v1/crm/inspections',
	INSPECTION_ACTUAL_CUSTOMER: '/api/v1/crm/inspections/actualcustomer',
	INSPECTIONS_VALIDATE: '/public/EmailConfirm',
	INSPECTION: {
		INDEX: '/index',
		MODULES: '/modules',
		ATTACHMENTS: '/attachments',
		RENOVATION_WORKS: 'renovationWorks'
	},
	PIPELINES_INSTANCE: '/api/v1/crm/pipelineInstance/step',
	PIPELINE: {
		SAVE: '/save',
		STEPS: '/api/v1/crm/pipelineInstance/steps'
	},
	LOCALTASKS: '/api/v1/localtasks',
	EPORTAL_LOCALTASKS: '/api/v1/eportal/localtasks',
	TASKS: '/api/v1/tasks',
	TASKS_COLLECTIONS: '/api/v1/taskcollections',
	WORKFLOW: '/api/v1/workflows',
	WORKFLOW_TYPE: '/api/v1/workflowType',
	LEADS: '/api/v1/crm/leads',
	CAMPAIGNS: '/api/v1/crm/campaigns',
	OPPORTUNITIES: '/api/v1/crm/opportunities',
	OPPORTUNITY: {
		LINES: '/api/v1/crm/opportunityLine'
	},
	REPORT_DEFINITIONS: '/api/v1/reports/definitions',
	REPORT_PARAMETERS: '/api/v1/reports/parameters',
	MESSAGE_TEMPLATES: '/api/v1/messagetemplates'
};
