import authService from "./authService";

const KEYS = {
	IS_MOBILE_APP: 'isMobileApp',
	DEVICE_TYPE: 'deviceType'
};

class AppMobileService {

	goToLogin() {
		console.log("MOBILE SERVICE: GO TO LOGIN");
		this.run('eportal://goToLogin');
	}

	goToPagoPAForIOS(url) {
		console.log("MOBILE SERVICE: GO TO PAGO PA FOR IOS");
		this.run(`solutionPAIos://${url}`);
	}

	loginSuccess(token, forced) {
		console.log("MOBILE SERVICE: LOGIN SUCCESS");
		authService.setSession(null)

		setTimeout(() => {
			this.run(`eportal://safariLoginSuccess?jsessionid=${token}`, forced);
		}, 100);
	}

	downloadDocument(url) {
		console.log("MOBILE SERVICE: DOWNLOAD DOCUMENT", url);
		this.run(`download://${url}`);
	}

	run(url, forced) {
		console.log("RUN", url);
		if (appMobileService.isMobile() || forced)
			document.location = url;
	}

	setMobileNavigation(){
		localStorage.setItem(KEYS.IS_MOBILE_APP, true);
	}

	setDeviceType(deviceType) {
		if(deviceType) 
			localStorage.setItem(KEYS.DEVICE_TYPE, deviceType);
	}

	getDeviceType() {
		return localStorage.getItem(KEYS.DEVICE_TYPE);
	}

	isMobile(){
		return localStorage.getItem(KEYS.IS_MOBILE_APP);
	}

	addMeta() {
		console.log("head", document.getElementsByTagName('head')[0]);

		const metaOptions = [
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
			},
			{
				name: 'apple-mobile-web-app-capable',
				content: 'yes'
			}
		]

		metaOptions.forEach(x => {
			let meta = document.createElement('meta');
			meta.id = x.name;
			meta.name = x.name;
			meta.content = x.content;
			document.getElementsByTagName('head')[0].appendChild(meta);
		});

		// blocco copia/incolla
		let body = document.getElementsByTagName('body')[0];
		body.onselectstart = function() {
				return false;
		};
		body.unselectable = "on";
		body.style['-moz-user-select'] = 'none';
		body.style['-webkit-user-select'] = 'none';	
	}

}

const appMobileService = new AppMobileService();

export default appMobileService;