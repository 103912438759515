import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Button,
	Grid,
	SvgIcon,
	makeStyles,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import {
	Download as DownloadIcon,
	PlusCircle as PlusCircleIcon,
	Upload as UploadIcon
} from 'react-feather';
import { SearchBox } from '../../';
import Filters from './Filters';

const useStyles = makeStyles(theme => ({
	root: {},
	action: {
		'& + &': {
			marginLeft: theme.spacing(2)
		}
	},
	actionIcon: {
		marginRight: theme.spacing(1)
	},
	search: {
		flex: 1
	},
}));

function Header({
	sort,
	filters,
	search,
	dataImport,
	dataExport,
	button,
	params,
	...rest
}) {
	const classes = useStyles();
	
	return (
		<Box p={2}>
			<Grid className={classes.root} container spacing={3} {...rest}>
				<Grid item xs={9}>
					<Box display="flex" alignItems="center">
						<Filters sort={sort} filters={filters} params={params} />

						{search && (
							<div className={classes.search}>
								<SearchBox
									name={search?.name}
									searchParams={search?.searchParams}
									handleChange={res => {
										search.handleChange(res);
									}}
								/>
							</div>
						)}
					</Box>
				</Grid>
				<Grid item xs={3}>
					<Box
						height="100%"
						display="flex"
						alignItems="center"
						justifyContent="flex-end"
					>
						{button && button?.can !== false && (
							<Button
								color="secondary"
								variant="contained"
								className={classes.action}
								onClick={() => button.handleClick()}
							>
								{button.icon !== false && (
									<SvgIcon fontSize="small" className={classes.actionIcon}>
										<PlusCircleIcon />
									</SvgIcon>
								)}
								{button.text || 'Nuovo'}
							</Button>
						)}

						{dataImport && dataImport?.can !== false && (
							<Tooltip title="Importa">
								<IconButton
									className={classes.action}
									ref={dataImport?.ref}
									onClick={() => dataImport.onClick()}
								>
									<SvgIcon fontSize="small">
										<UploadIcon />
									</SvgIcon>
								</IconButton>
							</Tooltip>
						)}

						{dataExport && dataExport?.can !== false && (
							<Tooltip title="Esporta">
								<IconButton
									className={classes.action}
									ref={dataExport?.ref}
									onClick={() => dataExport.onClick()}
								>
									<SvgIcon fontSize="small">
										<DownloadIcon />
									</SvgIcon>
								</IconButton>
							</Tooltip>
						)}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

Header.propTypes = {
	className: PropTypes.string
};

export default Header;
