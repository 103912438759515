import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import userInfo from 'src/services/userInfo';
import layoutService from 'src/services/layoutService';
import useSettings from 'src/hooks/useSettings';
import { getStoredSettings } from 'src/utils/settings';
import tenantsService from 'src/services/tenantService';
import { PROFILING } from 'src/utils/profilingKeys';

function MobileGuard({ children }) {
	const isAdmin = userInfo.is(PROFILING.ADMIN);
	const isAgent = userInfo.can(PROFILING.ROLE.AGENT) || userInfo.can(PROFILING.ROLE.AGENCY);
	const isBo = userInfo.can(PROFILING.ROLE.BO);
	const { settings, saveSettings } = useSettings();
  const storedSettings = getStoredSettings();
  const currentTenant = tenantsService.getCurrentTenant();

	if (!layoutService.isMobile() || isAdmin || isAgent || isBo)
		return <Redirect to="/app" />;

	if(currentTenant && storedSettings) {
		let storedParsed = JSON.parse(storedSettings)
		let tntTheme = currentTenant?.properties?.props?.thema;
		
		if(!storedParsed || (tntTheme && storedParsed?.theme !== tntTheme)) {
			let p = settings;

    	if(tntTheme) {
      	p.theme = tntTheme;
			}

			saveSettings(p);
		}
	}

	return children;
}

MobileGuard.propTypes = {
	children: PropTypes.any
};

export default MobileGuard;
