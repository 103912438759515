import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import { Box, TableCell, Typography, withStyles } from '@material-ui/core';
import { AutoSizer, Column, Table } from 'react-virtualized';
import layoutService from 'src/services/layoutService';
import { Skeleton } from '@material-ui/lab';

const styles = theme => ({
	flexContainer: {
		display: 'flex',
		alignItems: 'center',
		boxSizing: 'border-box',
		padding: '8px'
	},
	table: {
		'& .ReactVirtualized__Grid': {
			outline: 'none'
		},
		'& .ReactVirtualized__Table__headerRow': {
			flip: false,
			paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,

			'& .ReactVirtualized__Table__headerColumn': {
				width: '100%',
			}
		},
		'& .ReactVirtualized__Table__rowColumn': {
			width: '100%'
		}
	},
	tableRow: {
		cursor: 'pointer'
	},
	tableRowHover: {
		'&:hover': {
			backgroundColor: theme.palette.grey[200]
		}
	},
	tableCell: {
		flex: 1
	},
	noClick: {
		cursor: 'initial'
	},
	tableFooter: {
		padding: theme.spacing(1),
		paddingRight: theme.spacing(3),
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	noRecordFound: {
		position: 'absolute',
		zIndex: 999,
		background: theme.palette.background.default,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
});

class MuiVirtualizedTable extends React.PureComponent {
	static defaultProps = {
		headerHeight: 48,
		rowHeight: 80,
		footerHeight: 40,
		heightOffset: 0
	};

	constructor(props) {
		super(props);
		this.tableRef = React.createRef();
	}

	getRowClassName = ({ index }) => {
		const { classes, onRowClick } = this.props;

		return clsx(classes.tableRow, classes.flexContainer, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null
		});
	};

	cellRenderer = ({ rowData, cellData, dataKey, columnIndex }) => {
		const { columns, classes, rowHeight, onRowClick, isLoading } = this.props;
		let column = columns.find(x => x.dataKey === dataKey);
		let columnOption;

		if(column?.onClick) {
			columnOption = {
				onClick: () => column.onClick(rowData)
			}
		}
		
		let content =
			(columnIndex != null && column.date) || false
				? moment(cellData).format('DD/MM/YYYY')
				: cellData;
				
		if (this.props?.customCellRender)
			content = this.props?.customCellRender(dataKey, cellData, rowData);

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null
				})}
				variant="body"
				style={{ height: rowHeight, maxWidth: column?.maxWidth, padding: column?.padding }}
				align={(columnIndex != null && column?.align) || 'left'}
				{...columnOption}
			>
				{rowData?.length === 0 || isLoading ? (
					<Skeleton width="100%" />
				) : (
					content
				)}
			</TableCell>
		);
	};

	headerRenderer = ({ label, columnIndex }) => {
		const { headerHeight, columns, classes } = this.props;

		return (
			<TableCell
				component="div"
				className={clsx(
					classes.tableCell,
					classes.flexContainer,
					classes.noClick
				)}
				variant="head"
				style={{
					height: headerHeight,
					minWidth: columns[columnIndex].minWidth,
					manWidth: columns[columnIndex].maxWidth
				}}
				align={columns[columnIndex].align || 'left'}
			>
				<span>{label}</span>
			</TableCell>
		);
	};

	render() {
		const {
			classes,
			columns,
			rowHeight,
			footerHeight,
			heightOffset,
			isLoading,
			headerHeight,
			hideTableHeader,
			rowCount,
			...tableProps
		} = this.props;

		return (
			<AutoSizer>
				{({ height, width }) => {

					return (
						<React.Fragment>
							<Table
								ref={ref => this.tableRef = ref}
								height={!layoutService.isMobile() ? (height - heightOffset - footerHeight - (heightOffset || 90)) : ( height - footerHeight)}
								width={width}
								rowHeight={rowHeight}
								headerHeight={headerHeight}
								className={classes.table}
								rowCount={rowCount}
								{...tableProps}
								rowClassName={this.getRowClassName}
							>
								{columns.map(({ dataKey, hideMobile, hideTablet, hideDesktop, ...other }, index) => {

									if(hideMobile && layoutService.isMobile()) {
										return;
									}

									if(hideTablet && layoutService.isTablet()) {
										return;
									}

									if(hideDesktop && layoutService.isDesktop()) {
										return;
									}

									return (
										<Column
											key={dataKey}
											headerRenderer={headerProps =>
												this.headerRenderer({
													...headerProps,
													columnIndex: index
												})
											}
											className={classes.flexContainer}
											cellRenderer={this.cellRenderer}
											dataKey={dataKey}
											{...other}
										/>
									);
								})}
							</Table>

							{!isLoading && rowCount === 0 && (
								<Box
									className={classes.noRecordFound}
									height={height - heightOffset - headerHeight - footerHeight - (heightOffset || 90)}
									width={width}
									style={{
										bottom: footerHeight
									}}
								>
									<Typography variant="h2" color="textSecondary">
										Nessun risultato trovato
									</Typography>
								</Box>
							)}

							<Box
								className={classes.tableFooter}
								width={width}
								height={footerHeight}
								align="right"
								display="inline-flex"
							>
								<Typography variant="body1" color="textSecondary">
									Risultati ricerca: &nbsp;{' '}
								</Typography>
								<Typography variant="body1" color="textSecondary">
									{!isLoading ? rowCount : <Skeleton width={50} />}
								</Typography>
							</Box>
						</React.Fragment>
					);
				}}
			</AutoSizer>
		);
	}
}

MuiVirtualizedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			dataKey: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			numeric: PropTypes.bool,
			width: PropTypes.any.isRequired
		})
	).isRequired,
	headerHeight: PropTypes.number,
	onRowClick: PropTypes.func,
	rowHeight: PropTypes.number
};

export const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);
