import React, {
	useRef,
} from 'react';
import {
	Box,
	makeStyles,
	IconButton,
	SvgIcon
} from '@material-ui/core';
import {
	Menu as MenuIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.secondary.contrastText
	},
}));

function MobileMenu({ onMobileNavOpen }) {
	const classes = useStyles();
	const ref = useRef(null);

	return (
		<Box
			display="flex"
			alignItems="center"
			ref={ref}
		>
			<IconButton
				onClick={onMobileNavOpen}
			>
				<SvgIcon className={classes.icon}>
					<MenuIcon />
				</SvgIcon>
			</IconButton>
		</Box>
	);
}

export default MobileMenu;
