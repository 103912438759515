import axios from 'src/utils/axios';
import { URLS } from 'src/utils/url';

class ContractService {
	constructor() {}

	searchLines(params) {
		return axios.get(URLS.CONTRACTS + '/lines', { params: params });
	}

	getGroupedByPodPdr(params) {
		return axios.get(URLS.CONTRACT_LINES_GROUPED_PODPDR, { params: params });
	}

	search(params) {
		return axios.get(URLS.CONTRACTS, { params: params });
	}

	load(contractId,params) {
		return axios.get(`${URLS.CONTRACTS}/${contractId}`,{ params: params });
	}

	getTags(contractId) {
		return axios.get(
			`${URLS.CONTRACTS}/${contractId}/tag?code=contratto-template`
		);
	}

	getLines(params) {
		return axios.get(`${URLS.CONTRACT_LINES}`, { params: params });
	}

	update(params) {
		return axios.put(URLS.CONTRACTS, params);
	}

	updateBilling(id, params) {
		return axios.put(`${URLS.CONTRACT_LINES}/${id}/billing`, params);
	}

	updateCadastral(id, params) {
		return axios.put(`${URLS.CONTRACT_LINES}/${id}/cadastral`, params);
	}
}

export const contractService = new ContractService();
