/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath, useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
	Avatar,
	Box,
	Chip,
	Divider,
	Drawer,
	List,
	makeStyles,
	Typography,
} from '@material-ui/core';
import {
	Clock as ClockIcon,
	LogOut as LogOutIcon,
	MessageCircle as MessageIcon,
	User as UserIcon,
	Settings as SettingsIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import userInfo from 'src/services/userInfo';
import { useSnackbar } from 'notistack';
import { logout } from 'src/actions/accountActions';
import { CONSTANT_KEYS } from 'src/utils/constant';
import configService from 'src/services/configService';
import { THEMES } from 'src/constants';

var topBarColor = null;

function setTopBarColor(color) {
	topBarColor = color;
}

const navConfig = [
	{
		title: 'Anagrafica',
		icon: UserIcon,
		href: '/mobile/anagraphics'
		//href: '/mobile/anagraphic'
	},
	{
		title: 'Impostazione utente',
		href: '/mobile/account',
		icon: SettingsIcon
	},
	/*{
		title: 'Comunicazioni',
		icon: MessageIcon,
		href: '/mobile/communications',
		// info: () => (
		// 		<Chip
		// 			color="secondary"
		// 			size="small"
		// 			label="4"
		// 		/>
		// 	)	
	},*/
	{
		title: 'Storico Forniture',
		icon: ClockIcon,
		href: '/mobile/expired',
	},
	{
		title: 'Esci',
		icon: LogOutIcon,
		isLogout: true
	},
];


const useStyles = makeStyles((theme) => ({
	mobileDrawer: {
		width: '80%'
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)'
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64
	},
	/*header: {
		background: theme.palette.primary.main
	},*/
	header: {
	...(theme.name === THEMES.LIGHT_DEFAULT.name && topBarColor != null && topBarColor != 'undefined'
		? {
			background: topBarColor
		}
		: {
			background: theme.palette.primary.main
		})
	},
	headerTitle: {
		color: theme.palette.primary.contrastText
	},
	logo: {
		maxWidth: 200,
		maxHeight: 60
	}
}));

function NavBar({ openMobile, onMobileClose, }) {
	const topBarColor = configService.getConfigByKey(CONSTANT_KEYS.TOP_BAR_COLOR);
	setTopBarColor(topBarColor);
	const classes = useStyles();
	const location = useLocation();
	const { user } = useSelector((state) => state.account);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Box className={classes.header} pt={2}>
					<Box
						p={2}
						display="flex"
						justifyContent="center"
						height={70}
						alignItems="center"
					>
						<RouterLink to="/">
							<Logo size="small" className={classes.logo} />
						</RouterLink>
					</Box>

					<Box p={2}>
						<Box display="flex" justifyContent="center">
							<Avatar alt="User" className={classes.avatar} src={user.avatar} />
						</Box>

						<Box mt={2} textAlign="center">
							<Typography variant="h5" className={classes.headerTitle}>
								{`${user.firstName} ${user.lastName}`}
							</Typography>
						</Box>
					</Box>
				</Box>

				<Divider />

				<Box p={2}>
					<List>
						{navConfig.map(config =>
							userInfo.can(config.acl) ? (
								<NavItem
									href={config.href}
									icon={config.icon}
									key={config.title}
									info={config.info}
									title={config.title}
									onClick={config.isLogout ? handleLogout : config.onClick}
								/>
							) : null
						)}
					</List>
				</Box>
			</PerfectScrollbar>
		</Box>
	);

	async function handleLogout() {
		try {
			await dispatch(logout());
			history.push('/');
		} catch (error) {
			enqueueSnackbar('Impossibile effettuare il logout', {
				variant: 'error'
			});
		}
	}
	

	return (
		<Drawer
			anchor="right"
			classes={{ paper: classes.mobileDrawer }}
			onClose={onMobileClose}
			open={openMobile}
			variant="temporary"
		>
			{content}
		</Drawer>
	);
}

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default NavBar;
