// import 'src/mock';
import { enableES5 } from 'immer';
import 'moment/locale/it';
import 'nprogress/nprogress.css';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { I18nextProvider } from 'react-i18next';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import { Provider } from 'react-redux';
import 'src/assets/css/prism.css';
import { SettingsProvider } from 'src/context/SettingsContext';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import * as serviceWorker from 'src/serviceWorker';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/utils/settings';
import App from './App';
import i18n from './i18n';

enableES5();

const store = configureStore();
const settings = restoreSettings();

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<Provider store={store}>
			<SettingsProvider settings={settings}>
				<App />
			</SettingsProvider>
		</Provider>
	</I18nextProvider>,
	document.getElementById('root')
);

serviceWorker.unregister();
