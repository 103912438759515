import {
	Box,
	Card,
	Hidden,
	IconButton,
	makeStyles,
	SvgIcon,
	Tooltip,
	Typography
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ArrowRight as ArrowRightIcon, Menu as MenuIcon } from 'react-feather';
import { useHistory, useParams } from 'react-router';
import AvatarCommons from 'src/components/StatusAvatarCommons';
import Label from 'src/components/Label';
import InfinityTable from 'src/submodules/ui-components/infinity-table';
import axios from 'src/utils/axios';
import { TYPES_TASK, TYPES_TASK_AGENCY_STYLE } from 'src/utils/constant';
import { URLS } from 'src/utils/url';
import configService from 'src/services/configService';
import { CONSTANT_KEYS } from 'src/utils/constant';
import MenuDetails from 'src/components/MenuDetails';
import {
	AlertTriangle as AlertTriangleIcon
} from 'react-feather';

const rowHeight = 180;

const sortOptions = [
	{
		value: '-createTime',
		label: 'Creazione (più recente)'
	},
	{
		value: 'createTime',
		label: 'Creazione (meno recente)'
	}
];

const agencyStyle = configService.getConfigByKey(CONSTANT_KEYS.BE_AGENCY_STYLE);

const filtersOptions = [
	{
		label: 'Assegnati a me',
		value: {
			assign: 'me',
			'assign.operator': '='
		},
		default: true
	},
	{
		label: 'Assegnati al mio Reparto',
		value: {
			assignWorkspace: 'me'
		},
		default: true
	},
	{
		label: 'Stato Aperto',
		value: {
			open: true,
			close: false
		},
		default: true
	},
	{
		label: 'Stato Chiuso',
		value: {
			open: false,
			close: true,
		},
	}
]


const agencyStyleFilters = [
	{
		label: 'Assegnati a me',
		value: {
			assign: 'me',
			'assign.operator': '='
		},
		default: true
	},
	{
		label: 'Stato Aperto',
		value: {
			open: true,
			close: false
		},
		default: true
	},
	{
		label: 'Stato Chiuso',
		value: {
			open: false,
			close: true,
		},
	}
];

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.dark
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	taskcontainer: {
		maxHeight: 700
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	table: {
		flex: '1',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	typeLink: {
		'&:hover': {
			textDecoration: 'underline'
		},
		cursor: 'pointer'
	}
}));

function TaskList({ handleSidebar, sidebarOpen }) {
	const classes = useStyles();
	const params = useParams();
	const history = useHistory();
	const [dataSource, setDataSource] = useState(null);
	const [lastParams, setLastParams] = useState();
	const [attachedMenu, setAttachedMenu] = useState(null);

	moment.locale('it');

	function setColorLabel(storyboard) {
		var col = null;
		if (storyboard?.className && storyboard?.className !== '') col = 'blue';
		else col = 'orange';
		return <Label color={col}>{storyboard?.name}</Label>;
	}

	useEffect(() => {
		if(lastParams)
			getTask(lastParams);
	}, [params])

	function getTask(_params) {
		_params = {
			..._params,
			...params
		}

		setLastParams(_params);

		if (_params?.storyboardId) {

			axios
				//.get(URLS.TASKS, { params: _params })
				.get(`${URLS.TASKS}/eportal/`, { params: _params })
				.then(response => {
					let res = {
						...response.data,
						start: params?.start
					};

					setDataSource(res);
				})
		}
	}

	const goTo = t => {
		if (Boolean(t?.workflow)) {
			let workflow = t.workflow;
			let clazzName = workflow?.storyboard?.className;
			if (clazzName?.includes('InspectionApe') && workflow?.instanceId) {
				// localStorage.setItem(CONSTANT.INIT, CONSTANT.INIT);
				history.push(`/app/crm/inspections/${workflow?.instanceId}/edit`);
			} else if (clazzName?.includes('PreContract') && workflow?.instanceId) {
				history.push(`/app/crm/preContracts/${workflow?.instanceId}/edit`);
			} else if (clazzName?.includes('Partner') && workflow?.instanceId) {
				history.push(`/app/crm/partners/${workflow?.instanceId}/view`);
			} else {
				history.push(`/app/tickets/${t?.workflow?.id}/view`);
			}
		}
	};

	function customCellRender(dataKey, cellData, rowData) {
		if (dataKey === 'status') {
			return (
				<Box
					mt={10}
					alignItems="left"
					height={rowHeight}
					style={{ width: '100%' }}
				>
					<Typography variant="button" color="textPrimary">
						{' #' + rowData?.workflow?.id}
					</Typography>
				
					<AvatarCommons
						status={rowData?.completed}
						statusOk={true}
						statusKo={false}
						labels={{
							labelOk: 'CHIUSO',
							labelKo: 'APERTO'
						}}
					/>
				</Box>
			);
		}
		if (dataKey === 'task') {
			return (
				<Box
					mt={10}
					alignItems="left"
					height={rowHeight}
					style={{ width: '100%' }}
				>
					{rowData?.workflow && (
						<Typography variant="body2" color="textPrimary">
							{rowData?.workflow?.subject}
						</Typography>
					)}

					<Typography variant="button" color="textSecondary">
						{rowData?.taskName}
					</Typography>
					<Typography component="div" variant="body1" color="textPrimary">
						{rowData?.assignee?.firstName} {rowData?.assignee?.lastName}
						<Typography component="span" variant="body2" color="textSecondary">
							{' creato '}
						</Typography>
						<Typography component="span" variant="body2" color="textSecondary">
							{' il '}
							<Typography
								component="data"
								variant="body2"
								color="textSecondary"
							>
								{`${moment(rowData?.createTime).format('DD/MM/YYYY')}`}
							</Typography>
							<Typography
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{' alle '}
								<Typography
									component="data"
									variant="body2"
									color="textSecondary"
								>
									{`${moment(rowData?.createTime).format('HH:mm')}`}
								</Typography>
							</Typography>
						</Typography>
					</Typography>
				</Box>
			);
		}
		if (dataKey === 'details') {
			return (
				<Box
					mt={10}
					alignItems="left"
					height={rowHeight}
					style={{ width: '100%' }}
				>
					{rowData?.assignee && (
						<Typography
							variant="body2"
							color="textPrimary"
							className={classes.showCursor}
						>
							{`Assegnato a ${rowData?.assignee.name}`}
						</Typography>
					)}
					{rowData?.bpartner && (
						<div
							className={classes.typeLink}
							onClick={event =>
								setAttachedMenu({
									anchor: event.currentTarget,
									url: `/app/crm/customers/${rowData?.bpartner?.id}/view`,
									name: rowData?.bpartner?.name
								})
							}
						>
							<Typography variant="subtitle2" color="textPrimary">
								{`Cliente ${rowData?.bpartner?.name}`}
							</Typography>
						</div>
					)}
					{rowData?.owner && (
						<Typography variant="body1" color="textSecondary">
							{' @' + rowData?.owner?.name}
						</Typography>
					)}
					<Typography variant="body2" color="textSecondary">
						{'Aperto da ' + rowData?.elapsedInfo}
					</Typography>
					{rowData?.workflow && (
						<div>
							<Typography variant="body2" color="textSecondary">
								<AvatarCommons
									status={rowData?.workflow?.status}
									statusOk={'OPEN'}
									statusKo={'CLOSE'}
									labels={{
										labelOk: 'IN ESECUZIONE',
										labelKo: 'TERMINATO',
										labelDraft: 'BOZZA'
									}}
								/>
							</Typography>
							<Typography variant="button" color="textPrimary">
								{setColorLabel(rowData?.workflow?.storyboard)}
							</Typography>
						</div>
					)}
					<MenuDetails
						attachedMenu={attachedMenu}
						setAttachedMenu={setAttachedMenu}
					/>
				</Box>
			);
		}
		if (dataKey === 'todo') {
			return (
				<div>					
					{rowData?.countOpenToDo>0 && (
						<>
						<Box mb={2} textAlign="center">
							<Tooltip title={'ToDo aperti'}>
								<SvgIcon className={classes.alertIcon}>
									<AlertTriangleIcon />
								</SvgIcon>						
							</Tooltip>
						</Box>
						</>
					)}
				</div>
			);
		}
		if (dataKey === 'actions') {
			return (
				rowData?.workflow?.id && (
					<IconButton
						onClick={() => {
							goTo(rowData);
						}}
					>
						<SvgIcon fontSize="small">
							<ArrowRightIcon />
						</SvgIcon>
					</IconButton>
				)
			);
		}
		return cellData || null;
	}

	return (
		<div className={classes.root}>
			<Card
				style={{ marginLeft: '10px', marginRight: '10px' }}
				className={classes.table}
			>
				<Hidden mdUp>
					<IconButton
						className={classes.menuButton}
						onClick={() => handleSidebar(!sidebarOpen)}
					>
						<SvgIcon fontSize="small">
							<MenuIcon />
						</SvgIcon>
					</IconButton>
				</Hidden>

				<InfinityTable
					rowHeight={110}
					dataSource={dataSource}
					reload={getTask}
					columns={[
						{
							label: 'Stato',
							dataKey: 'status',
							width: 'auto',
							maxWidth: 100
						},
						{
							label: '',
							dataKey: 'todo',
							width: 'auto',
							maxWidth: 50,
							hideMobile: true
						},
						{
							label: 'Assegnazione',
							dataKey: 'task',
							width: 'auto',
							maxWidth: 300
						},
						{
							label: 'Dettaglio',
							dataKey: 'details',
							width: 'auto',
							hideMobile: true
						},
						{
							label: 'Azioni',
							dataKey: 'actions',
							align: 'right',
							width: 'auto',
							maxWidth: 100
						}
					]}
					customCellRender={(dataKey, cellData, rowData) =>
						customCellRender(dataKey, cellData, rowData)
					}
					sort={sortOptions}
					//filters={filtersOptions}
					filters={agencyStyle ? agencyStyleFilters : filtersOptions}
					search={{
						name: 'tasks',
						searchParams: ( agencyStyle ? TYPES_TASK_AGENCY_STYLE : TYPES_TASK),
					}}
				/>
			</Card>
		</div>
	);
}

export default TaskList;
