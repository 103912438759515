import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
	Button,
	ListItem,
	makeStyles,
	Typography
} from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'block',
		paddingTop: 0,
		paddingBottom: 0
	},
	itemLeaf: {
		display: 'flex',
		paddingTop: theme.spacing(0),
		paddingBottom: theme.spacing(0),
		borderBottom: `1px solid ${theme.palette.divider}`
	},
	button: {
		color: theme.palette.text.secondary,
		padding: '10px 8px',
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%'
	},
	buttonLeaf: {
		color: theme.palette.text.secondary,
		padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
		textDecoration: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		fontWeight: theme.typography.fontWeightRegular,
		'&.depth-0': {
			'& $title': {
				fontWeight: theme.typography.fontWeightMedium
			}
		}
	},
	icon: {
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(1)
	},
	title: {
		marginRight: 'auto'
	},
	active: {
		color: theme.palette.secondary.main,
		'& $title': {
			fontWeight: theme.typography.fontWeightMedium
		},
		'& $icon': {
			color: theme.palette.secondary.main
		}
	}
}));

function NavItem({
	title,
	href,
	children,
	icon: Icon,
	className,
	open: openProp,
	info: Info,
	onClick,
	...rest
}) {
	const classes = useStyles();
	const history = useHistory();

	const isActive = history.location.pathname === href;

	let paddingLeft = 8;
	const style = { paddingLeft };

	function handleClick() {
		if(onClick) onClick();
		else {
			if(history.location.pathname !== href) {
				window.history.replaceState(null, null, "/");
				history.push(href);
			}
		}

	}

	return (
		<ListItem
			className={clsx(classes.itemLeaf, className)}
			disableGutters
			key={title}
			{...rest}
		>
			<Button
				className={`${classes.buttonLeaf} ${isActive && classes.active}`}
				onClick={handleClick}
			>
				{Icon && (
					<Icon
						className={classes.icon}
						size="24"
					/>
				)}
				<Typography
					variant="h5"
					className={classes.title}
				>
					{title}
				</Typography>
				{Info && <Info className={classes.info} />}
				{href && <ArrowForwardIosIcon fontSize="small" color="disabled" />}
			</Button>
		</ListItem>
	);
}

NavItem.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	href: PropTypes.string,
	icon: PropTypes.any,
	info: PropTypes.any,
	open: PropTypes.bool,
	title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
	open: false
};

export default NavItem;
