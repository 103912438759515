import axios from 'src/utils/axios';
import { URLS } from 'src/utils/url';

class AnagraphicService {
	constructor() {}

	load() {
		return axios.get(URLS.ANAGRAPHIC);
	}

	loadCustomer(id) {
		return axios.get(URLS.ANAGRAPHIC_CUSTOMER+'/'+id);
	}

	update(params) {
		return axios.put(URLS.ANAGRAPHIC, params);
	}

	updateCustomer(id, params) {
		return axios.put(URLS.ANAGRAPHIC_CUSTOMER+'/'+id, params);
	}
}

export const anagraphicService = new AnagraphicService();