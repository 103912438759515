import {
	AppBar,
	Box,
	Hidden,
	IconButton,

	fade,
	colors,

	makeStyles,
	SvgIcon, Toolbar
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import CustomBreadcrumbs from './Breadcrumbs';
import Notifications from './Notifications';
import Settings from './Settings';
import { CONSTANT_KEYS } from 'src/utils/constant';
import configService from 'src/services/configService';


var topBarColor = null;

function setTopBarColor(color) {
	topBarColor = color;
}

const useStyles = makeStyles(theme => ({
	root: {
		zIndex: theme.zIndex.drawer + 100,
		...(theme.name === THEMES.LIGHT_DEFAULT.name && topBarColor != null && topBarColor != 'undefined'
			? {
					boxShadow: 'none',
					backgroundColor: fade(topBarColor, 1)
			  }
			: {
				boxShadow: 'none',
				backgroundColor: theme.palette.primary.main
			}),
		...(theme.name === THEMES.DARK.name
			? {
					backgroundColor: theme.palette.background.default
			  }
			: {})
	},
	toolbar: {
		maxHeight: 64
	},
	logo: {
		width: '100%',
		height: '100%',
		maxHeight: 64,
		maxWidth: 200,
		padding: theme.spacing(1),
		objectFit: 'contain'
	}
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
	const topBarColor = configService.getConfigByKey(CONSTANT_KEYS.TOP_BAR_COLOR);
	setTopBarColor(topBarColor);
	const classes = useStyles();

	return (
		<AppBar className={clsx(classes.root, className)} {...rest}>
			<Toolbar className={classes.toolbar}>
				<Hidden lgUp>
					<IconButton
						className={classes.menuButton}
						color="inherit"
						onClick={onMobileNavOpen}
					>
						<SvgIcon fontSize="small">
							<MenuIcon />
						</SvgIcon>
					</IconButton>
				</Hidden>
				<Hidden mdDown>
					<RouterLink to="/">
						<Logo className={classes.logo} size="small" />
					</RouterLink>
				</Hidden>

				<Hidden xsDown smDown>
					<CustomBreadcrumbs />
				</Hidden>
				<Box ml={2} flexGrow={1} />
				{/* <Search />
				<Contacts />
				*/}
				<Notifications />
				<Settings />
				<Account />
			</Toolbar>
		</AppBar>
	);
}

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func
};

export default TopBar;
