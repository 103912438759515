/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MobileLayout from 'src/layouts/MobileLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import GuestGuard from 'src/components/Guards/GuestGuard';
import MobileGuard from 'src/components/Guards/MobileGuard';
import DesktopGuard from './components/Guards/DesktopGuard';
import TaskView from './views/task/TaskView';
import systemRoutes from './submodules/commons-system/Routes';
import webmailRoutes from './submodules/commons-webmail/Routes';
import AuthLayout from './submodules/commons-auth';
import AuthGuard from './components/Guards/AuthGuard';
import { appMobileService } from './services';

const routesConfig = [
	{
		exact: true,
		path: '/',
		component: () => <Redirect to="/app" />
	},
	{
		exact: true,
		path: '/PagoPA',
		component: lazy(() => import('src/views/pages/PagoPA'))
	},
	{
		exact: true,
		path: '/404',
		component: lazy(() => import('src/views/pages/Error404View'))
	},

	{
		exact: true,
		path: '/500',
		component: lazy(() => import('src/views/pages/Error500'))
	},
	{
		path: '/auth',
		guard: GuestGuard,
		layout: AuthLayout,
		routes: [
			{
				exact: true,
				path: '/auth/mobile',
				component: lazy(() => import('src/submodules/commons-auth/Mobile'))
			},
			{
				exact: true,
				path: '/auth/login',
				component: lazy(() => import('src/submodules/commons-auth/Login'))
			},
			{
				exact: true,
				path: '/auth/login-unprotected',
				component: lazy(() => import('src/submodules/commons-auth/Login'))
			},
			{
				exact: true,
				path: '/auth/registration',
				component: lazy(() => import('src/submodules/commons-auth/Registration'))
			},
			{
				exact: true,
				path: '/auth/complete-registration',
				component: lazy(() => import('src/submodules/commons-auth/CompleteRegistration'))
			},
			{
				exact: true,
				path: '/auth/lostpassword',
				component: lazy(() => import('src/submodules/commons-auth/LostPassword'))
			},
			{
				exact: true,
				path: '/auth/resetpassword',
				component: lazy(() => import('src/submodules/commons-auth/ResetPassword'))
			},
			{
				exact: true,
				path: '/auth/invoices/:token',
				component: lazy(() => import('src/views/crm/Invoices/Messages'))
			},

			{
				component: () => <Redirect to="/auth/login" /> 
			}
		]
	},
	{
		path: '/validation',
		routes: [
			{
				exact: true,
				path: '/Validation/EmailConfirm/:token',
				component: lazy(() => import('src/views/validation/EmailConfirm'))
			},
			{
				component: () => <Redirect to="/404" /> 
			}		
		]
	},
	{
		guard: AuthGuard,
		routes: [
			{
				path: '/app',
				guard: DesktopGuard,
				layout: DashboardLayout,
				routes: [
					{
						exact: true,
						path: '/app',
						component: () => <Redirect to="/app/reports/dashboard" />
					},
					{
						exact: true,
						path: '/app/reports/dashboard',
						component: lazy(() => import('src/views/reports/Dashboard'))
					},
					{
						exact: true,
						path: '/app/reports/dashboardAnalysisPriceVolume',
						component: lazy(() => import('src/views/reports/DashboardAnalysisPriceVolume'))
					},
					{
						exact: true,
						path: '/app/reports/dashboardMessagingAnalysis',
						component: lazy(() => import('src/views/reports/DashboardMessagingAnalysis'))
					},
					{
						exact: true,
						path: '/app/reports/dashboardChurnRate',
						component: lazy(() => import('src/views/reports/DashboardChurnRate'))
					},
					{
						exact: true,
						path: '/app/reports/dashboardAnalysisFasiIncassi',
						component: lazy(() => import('src/views/reports/DashboardAnalysisFasiIncassi'))
					},
					{
						exact: true,
						path: '/app/reports/dashboardTakings',
						component: lazy(() => import('src/views/reports/DashboardTakings'))
					},
					{
						exact: true,
						path: '/app/account',
						component: lazy(() => import('src/views/pages/AccountView'))
					},
					{
						exact: true,
						path: '/app/crm/customers',
						component: lazy(() => import('src/submodules/eportal-ui/Customers/List'))
					},
					{
						exact: true,
						path: '/app/crm/customers/phone/:phoneNumber',
						component: lazy(() => import('src/submodules/eportal-ui/Customers/List'))
					},
					{
						exact: true,
						path: '/app/crm/customers/:customerId/view',
						component: lazy(() => import('src/submodules/eportal-ui/Customers/View'))
					},
					{
						exact: true,
						path: '/app/crm/customers/create/',
						component: lazy(() => import('src/submodules/eportal-ui/Customers/Edit'))
					},
					{
						exact: true,
						path: '/app/crm/customers/:customerId/edit',
						component: lazy(() => import('src/submodules/eportal-ui/Customers/Edit'))
					},
					{
						exact: true,
						path: '/app/crm/contracts',
						component: lazy(() => import('src/views/crm/Contracts/List'))
					},
					{
						exact: true,
						path: [
							'/app/crm/contracts/create',
							'/app/crm/contracts/create/:idCustomer',
							'/app/crm/contracts/:contractId/edit'
						],
						component: lazy(() => import('src/views/crm/Contracts/Edit'))
					},
					{
						exact: true,
						path: '/app/management/offers',
						component: lazy(() => import('src/views/crm/Offers/List'))
					},
					{
						exact: true,
						path: '/app/management/priceLists',
						component: lazy(() => import('src/views/crm/PriceList/List'))
					},
					{
						exact: true,
						path: '/app/management/priceLists/create',
						component: lazy(() => import('src/views/crm/PriceList/Edit'))
					},
					{
						exact: true,
						path: '/app/management/priceLists/:entityId/edit',
						component: lazy(() => import('src/views/crm/PriceList/Edit'))
					},
					{
						exact: true,
						path: '/app/management/usersGroupLegal',
						component: lazy(() => import('src/views/crm/UsersGroup/List'))
					},					
					{
						exact: true,
						path: '/app/management/usersGroupLegal/:entityId/edit',
						component: lazy(() => import('src/views/crm/UsersGroup/Edit'))
					},
					{
						exact: true,
						path: '/app/crm/preContracts',
						component: lazy(() => import('src/views/crm/PreContracts/List'))
					},
					{
						exact: true,
						path: ['/app/crm/preContracts/create','/app/crm/preContracts/:idCustomer/create'],
						component: lazy(() => import('src/views/crm/PreContracts/Edit'))
					},
					{
						exact: true,
						path: '/app/crm/preContracts/:idPrecontract/edit',
						component: lazy(() => import('src/views/crm/PreContracts/Edit'))
					},
					{
						exact: true,
						path: '/app/crm/practices',
						component: lazy(() => import('src/views/crm/Practice/List'))
					},
					{
						exact: true,
						path: ['/app/crm/practice/create','/app/crm/practice/:idCustomer/create'],
						component: lazy(() => import('src/views/crm/Practice/Edit'))
					},
					{
						exact: true,
						path: '/app/crm/practice/:idPractice/edit',
						component: lazy(() => import('src/views/crm/Practice/Edit'))
					},
					{
						exact: true,
						path: '/app/crm/inspections',
						component: lazy(() => import('src/views/crm/Inspection/List'))
					},
					{
						exact: true,
						path: [
							'/app/crm/inspections/gdpr',
						],
						component: lazy(() => import('src/views/crm/Inspection/GDPR/Gdpr'))
					},
					{
						exact: true,
						path: [
							'/app/crm/inspections/create',
							'/app/crm/inspections/:idInspection/edit'
						],
						component: lazy(() => import('src/views/crm/Inspection/Edit'))
					},
					{
						path: '/app/supply',
						routes: [
							{
								exact: true,
								path: '/app/supply/:contractLineId',
								component: lazy(() => import('src/views/mobile/Supply'))
							},
							{
								exact: true,
								path: '/app/supply/:contractLineId/invoices',
								component: lazy(() => import('src/views/mobile/Supply/Invoices'))
							},
							{
								exact: true,
								path: '/app/supply/:contractLineId/self-readings',
								component: lazy(() => import('src/views/crm/SelfReadings/List'))
							},
							{
								exact: true,
								path: '/app/supply/:contractLineId/self-readings/add',
								component: lazy(() => import('src/views/crm/SelfReadings/Edit'))
							},
							{
								exact: true,
								path: '/app/supply/:contractLineId/contract',
								component: lazy(() => import('src/views/mobile/Supply/Contracts/View'))
							},
							{
								exact: true,
								path: '/app/supply/:contractLineId/contract/edit/billing',
								component: lazy(() => import('src/views/mobile/Supply/Contracts/Edit/Billing'))
							},
							{
								exact: true,
								path: '/app/supply/:contractLineId/contract/edit/cadastral',
								component: lazy(() => import('src/views/mobile/Supply/Contracts/Edit/Cadastral'))
							},
							{
								component: () => <Redirect to="/404" />
							}
						]
					},
					{
						exact: true,
						path: '/app/expired-supplies',
						component: lazy(() => import('src/views/mobile/Expired'))
					},
					{
						exact: true,
						path: '/app/crm/partners',
						component: lazy(() => import('src/views/crm/SupplierPartner/List'))
					},
					{
						exact: true,
						path: '/app/crm/partners/create',
						component: lazy(() => import('src/views/crm/SupplierPartner/Edit'))
					},
					{
						exact: true,
						path: '/app/crm/partners/:id/view',
						component: lazy(() => import('src/views/crm/SupplierPartner/Edit'))
					},
					{
						exact: true,
						path: '/app/crm/orders',
						component: lazy(() => import('src/views/crm/Orders/OrderListView'))
					},
					{
						exact: true,
						path: '/app/management/products',
						component: lazy(() => import('src/views/management/ProductListView'))
					},
					{
						exact: true,
						path: '/app/management/products/create',
						component: lazy(() => import('src/views/management/ProductCreateView'))
					},
					{
						exact: true,
						path: '/app/sales/agencies',
						component: lazy(() => import('src/views/sales/Agencies/List'))
					},
					{
						exact: true,
						path: '/app/sales/agencies/:agencyId/view',
						component: lazy(() => import('src/views/sales/Agencies/View'))
					},
					{
						exact: true,
						path: '/app/sales/agencies/:agencyId/edit',
						component: lazy(() => import('src/views/sales/Agencies/Edit'))
					},
					{
						exact: true,
						path: '/app/sales/agencies/create',
						component: lazy(() => import('src/views/sales/Agencies/Edit'))
					},
					{
						exact: true,
						path: '/app/sales/agents',
						component: lazy(() => import('src/views/sales/Agents/List'))
					},
					{
						exact: true,
						path: '/app/sales/agents/:entityId/view',
						component: lazy(() => import('src/views/sales/Agents/Edit'))
					},
					{
						exact: true,
						path: '/app/sales/agents/create',
						component: lazy(() => import('src/views/sales/Agents/Edit'))
					},
					{
						exact: true,
						path: '/app/documents',
						component: lazy(() => import('src/submodules/commons-dms'))
					},
					{
						exact: true,
						path: '/app/crm/orders/:orderId',
						component: lazy(() => import('src/views/crm/Orders/OrderDetailsView'))
					},
					{
						exact: true,
						path: '/app/management/pods',
						component: lazy(() => import('src/views/crm/Pod'))
					},
					{
						exact: true,
						path: '/app/management/pdrs',
						component: lazy(() => import('src/views/crm/Pdr'))
					},
					{
						exact: true,
						path: '/app/crm/invoices',
						component: lazy(() => import('src/views/crm/Invoices/List'))
					},
					{
						exact: true,
						path: '/app/crm/invoices/:invoiceId',
						component: lazy(() => import('src/views/crm/Invoices/View'))
					},
					{
						exact: true,
						path: '/app/crm/consumption/:invoiceId',
						component: lazy(() => import('src/views/crm/Consumption/List'))
					},
					{
						exact: true,
						path: '/app/crm/consumptionHours/:pcmId',
						component: lazy(() => import('src/views/crm/ConsumptionHours/List'))
					},
					// { TODO:  DA ABILITARE QUANDO AVREMO I SERVIZI
					// 	exact: true,
					// 	path: '/app/crm/overdueInvoices',
					// 	component: lazy(() => import('src/views/crm/OverdueInvoices/List'))
					// },
					// {
					// 	exact: true,
					// 	path: '/app/crm/overdueInvoices/:invoiceId',
					// 	component: lazy(() => import('src/views/crm/OverdueInvoices/View'))
					// },
					{
						exact: true,
						path: '/app/crm/leads',
						component: lazy(() => import('src/views/crm/Leads/List'))
					},
					{
						exact: true,
						path: ['/app/crm/leads/create','/app/crm/leads/:idLead/edit'],
						component: lazy(() => import('src/views/crm/Leads/Edit'))
					},	
					{
						exact: true,
						path: '/app/crm/campaigns',
						component: lazy(() => import('src/views/crm/Campaigns/List'))
					},
					{
						exact: true,
						path: '/app/crm/allcampaigns',
						component: lazy(() => import('src/views/crm/Campaigns/Elements'))
					},
					{
						exact: true,
						path: ['/app/crm/campaigns/create'],
						component: lazy(() => import('src/views/crm/Campaigns/Create'))
					},
					{
						exact: true,
						path: ['/app/crm/campaigns/:idCampaign/edit'],
						component: lazy(() => import('src/views/crm/Campaigns/Create'))
					},
					{
						exact: true,
						path: '/app/crm/campaigns/:idCampaign/view',
						component: lazy(() => import('src/views/crm/Campaigns/View'))
					},
					{
						exact: true,
						path: '/app/crm/opportunities',
						component: lazy(() => import('src/views/crm/Opportunity/List'))
					},
					{
						exact: true,
						path: ['/app/crm/opportunities/create','/app/crm/opportunities/:idOpportunity/edit'],
						component: lazy(() => import('src/views/crm/Opportunity/Edit'))
					},
					{
						exact: true,
						path: '/app/comparator',
						component: lazy(() => import('src/views/comparator'))
					},
					{
						exact: true,
						path: '/app/management',
						component: () => <Redirect to="/app/management/customers" />
					},
					{
						exact: true,
						path: '/app/tickets',
						component: lazy(() => import('src/views/ticket/List'))
					},
					{
						exact: true,
						path: '/app/tickets/:ticketId/view',
						component: lazy(() => import('src/views/ticket/View'))
					},
					{
						exact: true,
						path: '/app/tickets/:ticketId/redirect',
						component: lazy(() => import('src/views/ticket/Redirect'))
					},
					{
						exact: true,
						path: ['/app/tickets/add','/app/tickets/add/:idCustomer'],
						component: lazy(() => import('src/views/ticket/Add'))
					},
					{
						exact: true,
						path: '/app/tickets/:ticketId/edit',
						component: lazy(() => import('src/views/ticket/Add'))
					},
					{
						path: ['/app/tasks/:storyboardId?'],
						layout: TaskView,
						routes: [
							{
								exact: true,
								path: ['/app/tasks/:storyboardId'],
								component: lazy(() => import('src/views/task/TaskView/TaskList'))
							}
						]
					},
					{
						exact: true,
						path: '/app/todo',
						component: lazy(() => import('src/views/task/TODO/List'))
					},
					{
						exact: true,
						path: '/app/calendar',
						component: lazy(() => import('src/submodules/commons-calendar'))
					},
					{
						exact: true,
						path: '/app/calendar/:idTask/close',
						component: lazy(() => import('src/submodules/commons-calendar/Close'))
					},
					{
						exact: true,
						path: '/app/kanban',
						component: lazy(() => import('src/views/kanban/KanbanView'))
					},
					{
						exact: true,
						path: ['/app/chat/new', '/app/chat/:threadKey'],
						component: lazy(() => import('src/submodules/commons-chat/ChatView'))
					},
					{
						exact: true,
						path: '/app/chat',
						component: () => <Redirect to="/app/chat/new" />
					},
					{
						exact: true,
						path: ['/app/mail/:type?/:type_id?/:mailboxid?/:id?'],//type puo essere folder oppure tag
						component: lazy(() => import('src/submodules/commons-webmail/MailView'))
					},
					{
						exact: true,
						path: '/app/projects/overview',
						component: lazy(() => import('src/views/projects/OverviewView'))
					},
					{
						exact: true,
						path: '/app/projects/browse',
						component: lazy(() => import('src/views/projects/ProjectBrowseView'))
					},
					{
						exact: true,
						path: '/app/projects/create',
						component: lazy(() => import('src/views/projects/ProjectCreateView'))
					},
					{
						exact: true,
						path: '/app/projects/:id',
						component: lazy(() => import('src/views/projects/ProjectDetailsView'))
					},
					{
						exact: true,
						path: '/app/projects',
						component: () => <Redirect to="/app/projects/browse" />
					},
					{
						exact: true,
						path: '/app/anagraphic',
						component: lazy(() => import('src/views/mobile/Anagraphic/View'))
					},
					{
						exact: true,
						path: '/app/anagraphic/edit',
						component: lazy(() => import('src/views/mobile/Anagraphic/Edit'))
					},
					{
						exact: true,
						path: '/app/anagraphic/:id',
						component: lazy(() => import('src/views/mobile/Anagraphic/View'))
					},
					{
						exact: true,
						path: '/app/anagraphic/:id/edit',
						component: lazy(() => import('src/views/mobile/Anagraphic/Edit'))
					},
					{
						exact: true,
						path: '/app/crm/anagraphic/:id',
						component: lazy(() => import('src/submodules/eportal-ui/Anagraphic/View'))
					},
					{
						exact: true,
						path: '/app/crm/anagraphic/:id/edit',
						component: lazy(() => import('src/submodules/eportal-ui/Anagraphic/Edit'))
					},
					{
						exact: true,
						path: '/app/extra/charts/apex',
						component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
					},
					{
						exact: true,
						path: '/app/extra/forms/formik',
						component: lazy(() => import('src/views/extra/forms/FormikView'))
					},
					{
						exact: true,
						path: '/app/extra/forms/redux',
						component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
					},
					{
						exact: true,
						path: '/app/extra/editors/draft-js',
						component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
					},
					{
						exact: true,
						path: '/app/extra/editors/quill',
						component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
					},
					{
						exact: true,
						path: '/app/pricing',
						component: lazy(() => import('src/views/pages/PricingView'))
					},
					...systemRoutes,
					...webmailRoutes,
					{
						component: () => <Redirect to="/404" />
					}
				]
			},
			{
				path: '/mobile',
				guard: MobileGuard,
				layout: MobileLayout,
				routes: [
					{
						exact: true,
						path: '/mobile',
						component: lazy(() => import('src/views/mobile'))
					},
					{
						path: '/mobile/supply',
						routes: [
							{
								exact: true,
								path: '/mobile/supply',
								component: lazy(() => import('src/views/mobile/Supply'))
							}, 
							{
								exact: true,
								path: '/mobile/supply/invoices',
								component: lazy(() => import('src/views/mobile/Supply/Invoices'))
							},
							{
								exact: true,
								path: '/mobile/supply/:contractLineId/self-readings',
								component: lazy(() => import('src/views/mobile/Supply/SelfReadings'))
							},
							{
								exact: true,
								path: '/mobile/supply/:contractLineId/self-readings/add',
								component: lazy(() => import('src/views/mobile/Supply/SelfReadings/Edit'))
							},
							{
								exact: true,
								path: '/mobile/supply/contract/',
								component: lazy(() => import('src/views/mobile/Supply/Contracts/View'))
							},
							{
								exact: true,
								path: '/mobile/supply/contract/edit/billing',
								component: lazy(() => import('src/views/mobile/Supply/Contracts/Edit/Billing'))
							},
							{
								exact: true,
								path: '/mobile/supply/contract/edit/cadastral',
								component: lazy(() => import('src/views/mobile/Supply/Contracts/Edit/Cadastral'))
							},
							{
								component: () => <Redirect to="/mobile" />
							}
						]
					},
					{
						exact: true,
						path: '/mobile/expired',
						component: lazy(() => import('src/views/mobile/Expired'))
					},
					{
						exact: true,
						path: '/mobile/anagraphics',
						//component: lazy(() => import('src/submodules/eportal-ui/Customers/List'))
						component: lazy(() => import('src/views/mobile/Anagraphic'))
					},
					{
						exact: true,
						path: '/mobile/anagraphic',
						component: lazy(() => import('src/views/mobile/Anagraphic/View'))
					},
					{
						exact: true,
						path: '/mobile/anagraphic/:id',
						component: lazy(() => import('src/views/mobile/Anagraphic/View'))
					},
					{
						exact: true,
						path: '/mobile/anagraphic/edit',
						component: lazy(() => import('src/views/mobile/Anagraphic/Edit'))
					},
					{
						exact: true,
						path: '/mobile/anagraphic/:id/edit',
						component: lazy(() => import('src/views/mobile/Anagraphic/Edit'))
					},
					{
						exact: true,
						path: '/mobile/account',
						component: lazy(() => import('src/views/pages/AccountView'))
					},
					{
						exact: true,
						path: '/mobile/communications',
						component: lazy(() => import('src/views/mobile/Communications'))
					},
					{
						component: () => <Redirect to="/mobile" />
					}
				]
			},
		]
	},
	{
		path: '*',
		layout: MainLayout,
		routes: [
			{
				exact: true,
				path: '/pricing',
				component: lazy(() => import('src/views/pages/PricingView'))
			},
			{
				component: () => <Redirect to="/404" />
			}
		]
	}
];

const renderRoutes = routes =>
	routes ? (
		<Suspense fallback={<LoadingScreen />}>
			<Switch>
				{routes.map((route, i) => {
					const Guard = route.guard || Fragment;
					const Layout = route.layout || Fragment;
					const Component = route.component;

					return (
						<Route
							key={i}
							path={route.path}
							exact={route.exact}
							render={props => (
								<Guard>
									<Layout>
										{route.routes ? (
											renderRoutes(route.routes)
										) : (
											<Component {...props} />
										)}
									</Layout>
								</Guard>
							)}
						/>
					);
				})}
			</Switch>
		</Suspense>
	) : null;

function Routes() {
	return renderRoutes(routesConfig);
}

export default Routes;
