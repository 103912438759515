import axios from 'src/utils/axios';
import { URLS } from 'src/utils/url';

class SelfReadingService {
	constructor() {}

	create(contractLineId, params) {
		return axios.post(`${URLS.SELF_READINGS}/${contractLineId}/save`, params );
	}

	search(contractLineId, params) {
		return axios.get(`${URLS.SELF_READINGS}/${contractLineId}`, {
			params: params
		});
	}

	prepareNew(contractLineId) {
		return axios.get(`${URLS.SELF_READINGS}/${contractLineId}/prepare`);
	}

	showSelfReadings(entity) {
		if (entity?.podPdr?.counterType?.typology === 'ORARIO') {
			return false;
		}

		return true;
	}

	searchConsumption(invoiceId, params) {
		return axios.get(`${URLS.ALL_CONSUMPTION_INVOICE}/${invoiceId}`, {
			params: params
		});
	}

	searchHoursConsumption(pcmId, params) {
		return axios.get(`${URLS.ALL_HOURS_CONSUMPTION_INVOICE}/${pcmId}`, {
			params: params
		});
	}
}

export const selfReadingService = new SelfReadingService();
