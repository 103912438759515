import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	root: {
		marginBottom: theme.spacing(3),
		paddingLeft: theme.spacing(3)
	}
}))

function Title() {
	const classes = useStyles();
	const title = useSelector((state) => state.layout.pageTitle);

	return (
		<Typography
			variant="h1"
			color="inherit"
			className={classes.root}
		>
			{ title }
		</Typography>
	)
}

export default Title;