/* eslint-disable react/no-array-index-key */
import {
	ListSubheader,
	makeStyles,
	Menu,
	MenuItem,
	SvgIcon
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import { Eye as EyeIcon } from 'react-feather';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
	root: {},
	container: {
		border: '1px solid',
		borderColor: theme.palette.divider,
		borderRadius: theme.shape.borderRadius
	},
	container_error: {
		border: 'none',
		borderColor: theme.palette.divider,
		borderRadius: theme.shape.borderRadius
	},
	table: {
		'& tbody tr td': {
			border: 0
		}
	},
	addCell: {
		width: 150
	},
	addBtn: {
		marginRight: theme.spacing(2)
	},
	list: {
		maxHeight: 150
	},
	attached: {
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		maxWidth: 250
	},
	addTxt: {
		color: theme.palette.text.hint
	},
	updateBtn: {
		position: 'absolute',
		right: 12
	},
	tagContainer: {
		maxWidth: '100%',
		flex: '0 0 auto'
	},
	iconBtn: {
		marginRight: theme.spacing(1)
	},
	attachedInfo: {
		maxWidth: 350
	}
}));

function MenuDetails({ attachedMenu, setAttachedMenu }) {
	const classes = useStyles();
	const history = useHistory();

	function goto(blank) {
		if (blank) {
			window.open(attachedMenu.url, '_blank');
		} else {
			history.push(attachedMenu.url);
		}
	}

	return (
		<Menu
			open={Boolean(attachedMenu)}
			onClose={() => setAttachedMenu(null)}
			anchorEl={attachedMenu?.anchor}
		>
			<>
				<ListSubheader>{attachedMenu?.name}</ListSubheader>
				<MenuItem
					onClick={() => {
						goto(false);
						setAttachedMenu(null);
					}}
				>
					<SvgIcon fontSize="small" className={classes.iconBtn}>
						<EyeIcon />
					</SvgIcon>
					Vai al dettaglio
				</MenuItem>
				<MenuItem
					onClick={() => {
						goto(true);
						setAttachedMenu(null);
					}}
				>
					<SvgIcon fontSize="small" className={classes.iconBtn}>
						<LaunchIcon />
					</SvgIcon>
					Apri in una nuova finestra
				</MenuItem>
			</>
		</Menu>
	);
}

MenuDetails.propTypes = {
	url: PropTypes.string
};

export default MenuDetails;
