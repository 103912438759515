import { makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import Page from 'src/components/Page';
import layoutService from 'src/services/layoutService';
import Sidebar from './Sidebar';
import TaskList from './TaskList';

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		display: 'flex',
		overflow: 'hidden',
		position: 'relative'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}));

function TaskView({ children }) {
	const classes = useStyles();
	const pageRef = useRef(null);
	const [sidebarOpen, setSidebarOpen] = useState(false);

	const pages = [{ title: 'Supporto' }, { title: 'Assegnazioni' }];
	layoutService.setBreadcrumbs(pages);

	const handleSidebar = res => {
		setSidebarOpen(res);
	};
	
	return (
		<Page className={classes.root} title="Lista assegnazioni" ref={pageRef}>
			<Sidebar
				containerRef={pageRef}
				handleSidebar={handleSidebar}
				sidebarOpen={sidebarOpen}
			/>
			{children}
		</Page>
	);
}

export default TaskView;
