/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
	Avatar,
	Box,
	Chip,
	Divider,
	Drawer,
	Hidden,
	Link,
	List,
	ListSubheader,
	makeStyles,
	Typography
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
	AlertCircle as AlertCircleIcon,
	BarChart as BarChartIcon,
	Briefcase as BriefcaseIcon,
	Calendar as CalendarIcon,
	Cast as CastIcon,
	Clock as ClockIcon,
	DollarSign as DollarSignIcon,
	Edit as EditIcon,
	Folder as FolderIcon,
	Layout as LayoutIcon,
	Lock as LockIcon,
	Mail as MailIcon,
	MessageCircle as MessageCircleIcon,
	PieChart as PieChartIcon,
	Settings as SettingsIcon,
	Share2 as ShareIcon,
	Shield as ShieldIcon,
	ShoppingCart as ShoppingCartIcon,
	Terminal as TerminalIcon,
	User as UserIcon,
	UserPlus as UserPlusIcon,
	Users as UsersIcon
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import userInfo from 'src/services/userInfo';
import NavItem from './NavItem';
import { CONSTANT_KEYS } from 'src/utils/constant';
import configService from 'src/services/configService';
import { THEMES } from 'src/constants';


var topBarColor = null;

function setTopBarColor(color) {
	topBarColor = color;
}

function renderNavItems({ items, ...rest }) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, item, ...rest }),
				[]
			)}
		</List>
	);
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
	const key = item.title + depth;

	if (item.items) {
		let total = 0;
		for (let subitem of item.items) {
			if (userInfo.can(subitem.acl)) {
				total++;
			}
		}

		if (total > 0) {
			const open = matchPath(pathname, {
				path: item.href,
				exact: false
			});

			if (userInfo.can(item.acl)) {
				acc.push(
					<NavItem
						depth={depth}
						icon={item.icon}
						key={key}
						info={item.info}
						open={Boolean(open)}
						title={item.title}
					>
						{renderNavItems({
							depth: depth + 1,
							pathname,
							items: item.items
						})}
					</NavItem>
				);
			}
		}
	} else {
		if (userInfo.can(item.acl)) {
			acc.push(
				<NavItem
					depth={depth}
					href={item.href}
					icon={item.icon}
					key={key}
					info={item.info}
					title={item.title}
				/>
			);
		}
	}

	return acc;
}

const useStyles = makeStyles(theme => ({
	mobileDrawer: {
		width: 256
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)'
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64
	},
	/*header: {
		background: theme.palette.primary.main
	},*/
	header: {
		...(theme.name === THEMES.LIGHT_DEFAULT.name && topBarColor != null && topBarColor != 'undefined'
			? {
				background: topBarColor
			}
			: {
				background: theme.palette.primary.main
			})
		},
	headerTitle: {
		color: theme.palette.primary.contrastText
	},
	logo: {
		maxWidth: 200,
		maxHeight: 60
	}
}));

function NavBar({ openMobile, onMobileClose }) {
	const topBarColor = configService.getConfigByKey(CONSTANT_KEYS.TOP_BAR_COLOR);
	setTopBarColor(topBarColor);
	const classes = useStyles();
	const location = useLocation();
	const { user } = useSelector(state => state.account);
	const { t } = useTranslation();
	const navConfig = [
		{
			subheader: 'Dashboards',
			acl: 'eportal.menu.dashboard',
			items: [
				{
					title: 'Generale',
					acl: 'eportal.menu.dashboard.general',
					icon: PieChartIcon,
					href: '/app/reports/dashboard'
				},
				{
					title: 'Analisi Prezzi Volumi',
					acl: 'eportal.menu.dashboard.analysispricevolume',
					icon: PieChartIcon,
					href: '/app/reports/dashboardAnalysisPriceVolume'
				},
				{
					title: 'Analisi Messagistica',
					acl: 'eportal.menu.dashboard.messaginganalysis',
					icon: PieChartIcon,
					href: '/app/reports/dashboardMessagingAnalysis'
				},
				{
					title: 'Churn Rate',
					acl: 'eportal.menu.dashboard.churnrate',
					icon: PieChartIcon,
					href: '/app/reports/dashboardChurnRate'
				},
				{
					title: 'Analisi Fasi Incassi',
					acl: 'eportal.menu.dashboard.analysisfasiincassi',
					icon: PieChartIcon,
					href: '/app/reports/dashboardAnalysisFasiIncassi'
				}
				/*,
				{
					title: 'Incassi',
					acl: 'eportal.menu.dashboard.takings',
					icon: PieChartIcon,
					href: '/app/reports/dashboardTakings'
				}*/
			]
		},
		{
			subheader: 'Area Agenzia',
			acl: 'eportal.menu.aa',
			items: [
				{
					title: 'Clienti',
					icon: UsersIcon,
					href: '/app/crm/customers',
					acl: 'eportal.menu.aa.customer',
					items: [
						{
							title: 'Clienti',
							acl: 'eportal.menu.aa.customers',
							href: '/app/crm/customers'
						},
						{
							title: 'Pre-Contratti',
							acl: 'eportal.menu.aa.precontracts',
							href: '/app/crm/preContracts'
						},
						{
							title: 'Pratiche Connessione',
							acl: 'eportal.menu.aa.practices',
							href: '/app/crm/practices'
						},
						{
							title: 'Pratiche Super/Ecobonus',
							acl: 'eportal.menu.aa.inspections',
							href: '/app/crm/inspections'
						},
						{
							title: 'Contratti',
							acl: 'eportal.menu.aa.contracts',
							href: '/app/crm/contracts'
						},
						{
							title: 'Fatture',
							acl: 'eportal.menu.aa.invoices',
							href: '/app/crm/invoices'
						},
						{
							title: 'POD',
							acl: 'eportal.menu.aa.pods',
							href: '/app/management/pods'
						},
						{
							title: 'PDR',
							acl: 'eportal.menu.aa.pdrs',
							href: '/app/management/pdrs'
						},
						{
							title: 'Offerte',
							acl: 'eportal.menu.aa.offers',
							href: '/app/management/offers'
						},
						{
							title: 'Listini',
							acl: 'eportal.menu.aa.priceLists',
							href: '/app/management/priceLists'
						},
						{
							title: 'Comparatore fatture',
							acl: 'eportal.menu.aa.comparator',
							href: '/app/comparator'
						}
					]
				},
				{
					title: 'Rete vendita',
					href: '/app/sales',
					acl: 'eportal.menu.ns',
					icon: ShareIcon,
					items: [
						{
							title: 'Agenzie',
							acl: 'eportal.menu.ns.agencies',
							href: '/app/sales/agencies'
						},
						{
							title: 'Agenti',
							acl: 'eportal.menu.ns.agents',
							href: '/app/sales/agents'
						}
					]
				},				
				{
					title: 'Associazioni Legale',
					acl: 'eportal.menu.aa.legal',
					href: '/app/management/usersGroupLegal',					
					icon: BriefcaseIcon
				},
				//  TODO:  DA ABILITARE QUANDO FAREMO QUESTA PARTE
				// {
				// 	title: 'Commissioning',
				// 	icon: ShoppingCartIcon,
				// 	href: '/app/management/products',
				// 	acl: 'eportal.menu.co',
				// 	items: [
				// 		{
				// 			title: 'Commissioning',
				// 			acl: 'eportal.menu.co.commissioning',
				// 			href: '/app/management/products'
				// 		}
				// 	]
				// }
			]
		},
		{
			subheader: 'Area Cliente',
			acl: 'eportal.menu.ca',
			items: [
				{
					title: 'Contratti',
					acl: 'eportal.menu.ca.contracts',
					href: '/app/crm/contracts',
					icon: ReceiptIcon
				},

				{
					title: 'Pre-Contratti',
					acl: 'eportal.menu.ca.precontracts',
					href: '/app/crm/preContracts',
					icon: ReceiptIcon
				},
				// 
				//{
				//	title: 'Pratiche Super/Ecobonus',
				//	acl: 'eportal.menu.aa.inspections',
				//	href: '/app/crm/inspections',
				//	icon: ReceiptIcon
				//},
				{
					title: 'Fatture',
					acl: 'eportal.menu.ca.invoices',
					href: '/app/crm/invoices',
					icon: DollarSignIcon
				},
				// {   TODO:  DA ABILITARE QUANDO AVREMO I SERVIZI
				// 	title: 'Fatture in mora',
				// 	acl: 'eportal.menu.ca.overdue.invoices',
				// 	href: '/app/crm/overdueInvoices',
				// 	icon: DollarSignIcon
				// },
				{
					title: 'Storico Forniture',
					acl: 'eportal.menu.ca.invoices',
					href: '/app/expired-supplies',
					icon: ClockIcon
				}
			]
		},
		{
			subheader: 'Area Partner',
			acl: 'eportal.menu.pa',
			items: [
				{
					title: 'Qualificazioni',
					acl: 'eportal.menu.pa.qualifications',
					href: '/app/crm/partners',
					icon: UserIcon
				}
			]
		},
		{
			subheader: 'Marketing',
			acl: 'eportal.menu.ma',
			items: [
				{
					title: 'Potenziali clienti',
					acl: 'eportal.menu.ma.leads',
					href: '/app/crm/leads',
					icon: UserIcon
				},
				{
					title: `${t('Opportunità')}`,
					acl: 'eportal.menu.ma.opportunities',
					href: '/app/crm/opportunities',
					icon: UserIcon
				},
				{
					title: 'Campagne',
					acl: 'eportal.menu.ma.campaigns',
					href: '/app/crm/campaigns',
					icon: UserIcon
				},
				{
					title: 'Tutte le campagne',
					acl: 'eportal.menu.ma.allcampaigns',
					href: '/app/crm/allcampaigns',
					icon: UserIcon
				}
			]
		},
		{
			subheader: 'Supporto',
			href: '/app/pages',
			acl: 'eportal.menu.sa',
			items: [
				{
					title: 'Mail',
					acl: 'eportal.menu.sa.mails',
					href: '/app/mail/folder/inbox/all',
					icon: MailIcon
					// info: () => (
					// 	<Chip
					// 		color="secondary"
					// 		size="small"
					// 		label="4"
					// 	/>
					// )
				},
				{
					title: 'Ticket',
					acl: 'eportal.menu.sa.tickets',
					href: '/app/tickets',
					icon: CastIcon
					// ,
					// info: () => (
					// 	<Chip
					// 		color="secondary"
					// 		size="small"
					// 		label="4"
					// 	/>
					// )
				},
				{
					title: 'Assegnazioni',
					acl: 'eportal.menu.sa.tasks',
					href: '/app/tasks',
					icon: CastIcon
					// ,
					// info: () => (
					// 	<Chip
					// 		color="secondary"
					// 		size="small"
					// 		label="4"
					// 	/>
					// )
				},
				{
					title: 'TODO',
					acl: 'eportal.menu.sa.todo',
					href: '/app/todo',
					icon: CastIcon
				},
				{
					title: 'Chat',
					acl: 'eportal.menu.sa.chats',
					href: '/app/chat',
					icon: MessageCircleIcon,
					info: () => <Chip color="secondary" size="small" label="Aggiornato" />
				},
				{
					title: 'Calendario',
					acl: 'eportal.menu.sa.calendars',
					href: '/app/calendar',
					icon: CalendarIcon
				},
				{
					title: 'Offerte commerciali',
					acl: 'eportal.menu.sa.offers',
					href: '/app/pricing',
					icon: DollarSignIcon
				},
				{
					title: 'Documenti',
					acl: 'commons.menu.file',
					href: '/app/documents',
					icon: FolderIcon
				}
			]
		},
		// {
		// 	subheader: 'Anagrafiche',
		// 	items: [
		// 		{
		// 			title: 'Fornitori e Partner',
		// 			acl: 'eportal.menu.supplierAndPartner',
		// 			href: '/app/registry/supplierPartner',
		// 			icon: UserIcon
		// 		}
		// 	]
		// },
		{
			subheader: 'Impostazioni',
			items: [
				{
					title: 'Impostazione utente',
					href: '/app/account',
					icon: UserIcon
				},
				{
					title: 'Sistema',
					acl: 'eportal.menu.settings.system',
					icon: BriefcaseIcon,
					items: [
						{
							title: 'Accessi',
							icon: BriefcaseIcon,
							items: [
								{
									title: 'Utenti',
									acl: 'commons.menu.usermanagement',
									href: '/app/system/users',
									icon: BriefcaseIcon
								},
								{
									title: 'Permessi',
									acl: 'commons.menu.usermanagement',
									href: '/app/system/groups',
									icon: BriefcaseIcon
								},
								{
									title: 'Configurazioni',
									acl: 'commons.menu.usermanagement',
									href: '/app/system/configurations',
									icon: BriefcaseIcon
								}
							]
						},
						{
							title: 'Configurazione',
							icon: BriefcaseIcon,
							items: [
								{
									title: 'Tenant',
									acl: 'commons.menu.tenant',
									href: '/app/system/tenants',
									icon: BriefcaseIcon
								},
								{
									title: 'Tipologia Workflow',
									acl: 'commons.menu.ticket',
									href: '/app/system/ticketType',
									icon: BriefcaseIcon
								},
								{
									title: 'Parametri Report',
									acl: 'commons.menu.reportdefinition',
									href: '/app/system/reportdefinition',
									icon: BriefcaseIcon
								}
							]
						},
						{
							title: 'Documenti',
							icon: BriefcaseIcon,
							items: [
								{
									title: 'Tag',
									acl: 'commons.menu.tagDocuments',
									href: '/app/system/tags',
									icon: BriefcaseIcon
								},
								{
									title: 'Template',
									acl: 'commons.menu.templateDocuments',
									href: '/app/system/templates',
									icon: BriefcaseIcon
								}
							]
						},
						{
							title: 'Mailbox',
							icon: BriefcaseIcon,
							items: [
								{
									title: 'Mailbox',
									acl: 'commons.menu.mailbox',
									href: '/app/system/mailbox',
									icon: BriefcaseIcon
								},
								{
									title: 'Template',
									acl: 'commons.menu.templateMessages',
									href: '/app/webmail/templates',
									icon: BriefcaseIcon
								}
							]
						},
						{
							title: 'Workspace',
							icon: BriefcaseIcon,
							items: [
								{
									title: 'Workspace',
									acl: 'commons.menu.workspace',
									href: '/app/system/workspaces',
									icon: BriefcaseIcon
								},
								{
									title: 'Tipologia Workspace',
									acl: 'commons.menu.workspace',
									href: '/app/system/workspacesType',
									icon: BriefcaseIcon
								}
							]
						},
						{
							title: 'Develop',
							icon: BriefcaseIcon,
							items: [
								{
									title: 'Storyboard',
									acl: 'commons.menu.storyboard',
									href: '/app/system/storyboards',
									icon: BriefcaseIcon
								},
								{
									title: 'Migrazione Storyboard',
									acl: 'commons.menu.storyboard',
									href: '/app/system/migrations',
									icon: BriefcaseIcon
								}
							]
						},
						{
							title: 'Amministrazione',
							icon: BriefcaseIcon,
							items: [
								{
									title: 'Console Python',
									acl: 'commons.menu.console',
									href: '/app/system/console',
									icon: TerminalIcon
								},
								{
									title: 'Sistema',
									acl: 'commons.menu.system',
									href: '/app/system/system',
									icon: SettingsIcon
								}
							]
						}
					]
				}
			]
		},
		{
			subheader: 'Extra',
			acl: 'eportal.menu.extra',
			items: [
				{
					title: 'Profilo',
					acl: 'eportal.menu.profile',
					href: '/app/social/profile'
				},
				{
					title: 'Extra',
					acl: 'eportal.menu.experimental',
					href: '/app/extra',
					icon: BriefcaseIcon,
					items: [
						{
							title: 'Projects Platform',
							href: '/app/projects',
							icon: BriefcaseIcon,
							items: [
								{
									title: 'Overview',
									href: '/app/projects/overview'
								},
								{
									title: 'Browse Projects',
									href: '/app/projects/browse'
								},
								{
									title: 'Create Project',
									href: '/app/projects/create'
								},
								{
									title: 'View Project',
									href: '/app/projects/1'
								},
								{
									title: 'Ordini',
									href: '/app/crm/orders'
								}
							]
						},
						{
							title: 'Social Platform',
							href: '/app/social',
							icon: ShareIcon,
							items: [
								{
									title: 'Profilo',
									href: '/app/social/profile'
								},
								{
									title: 'Feed',
									href: '/app/social/feed'
								}
							]
						},
						{
							title: 'Simulatore fatture old',
							href: '/app/projects/overview',
							icon: DollarSignIcon
						},
						{
							title: 'Login',
							href: '/login-unprotected',
							icon: LockIcon
						},
						{
							title: 'Register',
							href: '/register-unprotected',
							icon: UserPlusIcon
						},
						{
							title: 'Login: Guest Protected',
							href: '/login',
							icon: ShieldIcon
						},
						{
							title: 'Register: Guest Protected',
							href: '/register',
							icon: ShieldIcon
						},
						{
							title: 'Charts',
							href: '/app/extra/charts',
							icon: BarChartIcon,
							items: [
								{
									title: 'Apex Charts',
									href: '/app/extra/charts/apex'
								}
							]
						},
						{
							title: 'Forms',
							href: '/app/extra/forms',
							icon: EditIcon,
							items: [
								{
									title: 'Formik',
									href: '/app/extra/forms/formik'
								},
								{
									title: 'Redux Forms',
									href: '/app/extra/forms/redux'
								}
							]
						},
						{
							title: 'Editors',
							href: '/app/extra/editors',
							icon: LayoutIcon,
							items: [
								{
									title: 'DraftJS Editor',
									href: '/app/extra/editors/draft-js'
								},
								{
									title: 'Quill Editor',
									href: '/app/extra/editors/quill'
								}
							]
						},
						{
							title: 'Error',
							href: '/404',
							icon: AlertCircleIcon
						}
					]
				}
			]
		}
	];

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box className={classes.header} pt={2}>
						<Box
							p={2}
							display="flex"
							justifyContent="center"
							height={70}
							alignItems="center"
						>
							<RouterLink to="/">
								<Logo size="small" className={classes.logo} />
							</RouterLink>
						</Box>

						<Box p={2}>
							<Box display="flex" justifyContent="center">
								<Avatar
									alt="User"
									className={classes.avatar}
									src={user.avatar}
								/>
							</Box>
							
							<Box mt={2} textAlign="center">
								<Typography variant="h5" className={classes.headerTitle}>
									{`${user.firstName} ${user.lastName}`}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Hidden>
				<Hidden lgDown>
					<Box p={2}>
						<Box display="flex" justifyContent="center">
							<RouterLink to="/app/account">
								<Avatar
									alt="User"
									className={classes.avatar}
									src={user.avatar}
								/>
							</RouterLink>
						</Box>
						<Box mt={2} textAlign="center">
							<Link
								component={RouterLink}
								to="/app/account"
								variant="h5"
								color="textPrimary"
								underline="none"
							>	
								{`${user.name}`}
							</Link>
						</Box>
					</Box>
				</Hidden>

				<Divider />

				<Box p={2}>
					{navConfig.map(config =>
						userInfo.can(config.acl) ? (
							<List
								key={config.subheader}
								subheader={
									<ListSubheader disableGutters disableSticky>
										{config.subheader}
									</ListSubheader>
								}
							>
								{renderNavItems({
									items: config.items,
									pathname: location.pathname
								})}
							</List>
						) : null
					)}
				</Box>
			</PerfectScrollbar>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default NavBar;
