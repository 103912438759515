import axios from "src/utils/axios";
import { URLS } from "src/utils/url";

class DashboardService {
	constructor() {
	}

	getMobile() {
		return axios
			.get(URLS.DASHBOARD_CUSTOMER)
	}

	getContract(params) {
		return axios.get(`${URLS.DASHBOARD}/contract`, { params })
	}

	getChurnRate(params) {
		return axios.get(`${URLS.DASHBOARD}/churnRate`, { params })
	}

	getChurnRatePrecontract(params) {
		return axios.get(`${URLS.DASHBOARD}/churnRatePrecontract`, { params })
	}	

	exportChurnRate(params) {
		return axios.get(`${URLS.DASHBOARD}/churnRate/export`, {
			params,
			responseType: 'blob'
		});
	}

	getTakings(params) {
		return axios.get(`${URLS.DASHBOARD}/takings`, { params })
	}

	getAnalysisPriceVolume(params) {
		return axios.get(`${URLS.DASHBOARD}/analysisPriceVolume`, { params })
	}

	exportAnalysisPriceVolume(params) {
		return axios.get(`${URLS.DASHBOARD}/analysisPriceVolume/export`, { params })
	}

	getMessagingAnalysis(params) {
		return axios.get(`${URLS.DASHBOARD}/messagingAnalysis`, { params })
	}

	exportMessagingAnalysis(params) {
		return axios.get(`${URLS.DASHBOARD}/messagingAnalysis/export`, { params })
	}

	getAnalysisFasiIncassi(params) {
		return axios.get(`${URLS.DASHBOARD}/analysisFasiIncassi`, { params })
	}

	exportAnalysisFasiIncassi(params) {
		return axios.get(`${URLS.DASHBOARD}/analysisFasiIncassi/export`, { params })
	}

}

const dashboardService = new DashboardService();

export default dashboardService;
